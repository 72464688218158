import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-validator',
    templateUrl: './validator.component.html',
    styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent implements OnInit {
    form: FormGroup;
    siteTitle = '';
    domain = '';
    // Loadings
    loading = true;
    // Traductions
    i18n: any = {};

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private router: Router,
        private localStorageService: LocalStorageService,
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.createForm();
        this.getSiteData();
    }

    getTranslations(): void {
        if (this.localStorageService.getLocalStorageItem('translations')) {
            this.i18n = { ...this.sharedService.getTranslationsOf('CertificatesValidate') };
        }
    }

    getSiteData(): void {
        const siteMetadata = localStorage.getItem('site_metadata');

        if (siteMetadata) {
            const title = JSON.parse(siteMetadata)?.settingsGeneral?.site_title;

            if (title) {
                this.siteTitle = title;
                this.domain = window.location.hostname;
            }
        }
    }

    createForm(): void {
        this.form = this.fb.group({
            code: ['', Validators.required],
        });

        this.loading = false;
    }

    validateCertificates(): void {
        if (this.form.valid) {
            this.router.navigate([`/certificates/${this.form.controls.code.value}/validate`]);
        }
    }
}
