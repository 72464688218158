import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { GradesService } from 'src/app/pages/disciplines/components/grades/grades.service';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Subscription } from 'rxjs';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';

@Component({
  selector: 'app-new-category-form',
  templateUrl: './new-category-form.component.html',
  styleUrls: ['./new-category-form.component.scss'],
})
export class NewCategoryFormComponent implements OnInit, OnDestroy {
  // @Input() period: any;
  @Input() isModalOpen: boolean;
  @Output() sendIsModalOpen = new EventEmitter();
  @Input() controlButton: boolean = false;

  editInput: any = [null];
  categoryForm: UntypedFormGroup;
  categoriesSaver: any = [];
  categoriesUpdater: any = [];
  iteratorSaver: number = 0;
  iteratorUpdater: number = 0;
  weightSum: any = 0;
  period: any = null;
  i18n: any = [];
  subscription: Subscription[] = [];
  maxGrade = 0;

  constructor(
    private FormBuilder: UntypedFormBuilder,
    private gradesService: GradesService,
    public platModalService: PlatformModalsService,
    private sharedSerivce: SharedService,
    private disciplineDetailService: DisciplineDetailService,
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.createCategoryForm();
    this.getDisciplineDetail();

    let sub1 = this.gradesService.categories$.subscribe((period) => {
      if (period.length !== 0) {
        this.period = period;
        this.createCategoryForm();
        this.initFormArray(this.period?.categories);
        this.calculateCategories(this.period?.categories);
      }
    });

    this.gradesService.periods$.subscribe((periods: any) => {
      if (periods.length > 0 && this.period !== null) {
        let period = periods.find((period: any) => {
          return period.id == this.period?.id;
        });
      }
    })

    let sub2 = this.gradesService.periods$.subscribe((periods: any) => {
      if (periods.length > 0 && this.period !== null) {
        let period = periods.find((period: any) => {
          return period.id == this.period?.id;
        });
        if (period !== null && period !== undefined) {

          if (Object.keys(period).length > 0) {
            console.log('this.period ', this.period);

            this.period = period;
            this.calculateCategories(this.period?.categories);
          }
        }
      }
    });

    this.subscription.push(sub1, sub2);
  }

  getDisciplineDetail() {
    this.disciplineDetailService
        .disciplineBasic$
        .subscribe({
            next: (discipline) => {
                const maxGrade = discipline?.global_grades_settings?.max_grade;
                if (!!maxGrade) {
                    this.maxGrade = maxGrade;
                }
            }
        });
  }

  getTranslations() {
    this.i18n = { ...this.sharedSerivce.getTranslationsOf('Disciplines'), ...this.sharedSerivce.getTranslationsOf('Grades') };
  }

  //retorna o arrays de roles dentro do formulário de Users
  get categories() {
    return this.categoryForm.get('categories') as UntypedFormArray;
  }

  //retorna o arrays de roles dentro do formulário de Users
  get newCategories() {
    return this.categoryForm.get('newCategories') as UntypedFormArray;
  }

  calculateCategories(categories: any) {
    this.weightSum = 0;
    categories.forEach((element: any) => {
      // Se o tipo de cálculo for diferente de média simples (sum)
      if (this.period.calculation_type !== 'sum') {
        this.weightSum = this.weightSum + element.weight;
      }

    });
  }

  createCategoryForm() {
    this.categoryForm = this.FormBuilder.group({
      categories: this.FormBuilder.array([]),
      newCategories: this.FormBuilder.array([]),
    });
  }

  createNewCategoryForm(newCategories: any, disabled?: boolean): UntypedFormGroup {
    let valueName = { value: newCategories.name, disabled: disabled };
    let valueWeight = '';

    valueWeight = newCategories.weight?.toString().replace('.', ',') || '';

    if (this.period?.calculation_type === 'weighted_average') {
        if (valueWeight) valueWeight += '%';
    }

    return new UntypedFormGroup({
      name: new UntypedFormControl(valueName, [Validators.required]),
      weight: new UntypedFormControl({ value: valueWeight, disabled: disabled }, [Validators.required]),
      id: new UntypedFormControl(newCategories.id, []),
    });
  }


  initFormArray(categories: any[]) {
    const formArray = this.categories;
    categories.map((item) => {
      console.log('category', item);

      formArray.push(this.createNewCategoryForm(item, true));
    });
    this.categoryForm.setControl('categories', formArray);
    console.log('this.categories', this.categoryForm);

  }

  editCategory(id: any, i: any) {
    const updater = {
      category_id: id,
      index: i,
    };

    this.categoriesUpdater.push(updater);
    this.editInput[i] = true;

    const nameControl = this.categories.controls[i].get('name');
    const weightControl = this.categories.controls[i].get('weight');

    nameControl?.enable();
    weightControl?.enable();

    if (this.period?.calculation_type === 'sum') {
      const currentWeight = weightControl?.value || '';
      weightControl?.setValue(currentWeight); // Atualiza o valor sem disparar eventos extras
    }

    weightControl?.updateValueAndValidity(); // Revalida o controle
  }

  addFormCategory() {
    const newCategory = {
      name: '',
      weight: '',
    };
    this.newCategories.push(this.createNewCategoryForm(newCategory));
  }

  closeModal(name: 'save' | 'close') {
    this.categoryForm.controls['newCategories'].reset();
    this.isModalOpen = false;
    this.sendIsModalOpen.emit({ value: this.isModalOpen, name: name });
    this.categoriesSaver = [];
    this.iteratorSaver = 0;
    this.iteratorUpdater = 0;
    this.categoriesUpdater = [];
    this.editInput = [];
  }

  saveCategories() {
    this.categoriesSaver = [];

    // post Categories
    let newCategories = this.categoryForm.controls['newCategories']['controls'];

    for (const iterator of newCategories) {
      if (iterator.value.id == null) {
        const category = {
          name: iterator.value.name,
          weight: iterator.value.weight.toString()
          .replace('%', '')
          .replace(',', '.'),
        };

        this.categoriesSaver.push(category);
      }
    }

    if (this.period?.calculation_type !== 'sum') {

      if (!this.isMaxWeightValid()) {
        this.platModalService.toggle(
          'message',
          this.i18n.grades_weight_limit_exceed,
          'close'
        );
        return;
      }
    }
    if (this.categoriesUpdater.length > 0) {
      this.patchCategory(this.categoriesUpdater);
      // this.callNextCategoryToUpdate(this.iteratorUpdater,this.categoriesUpdater);
    }
    if (this.categoriesSaver.length > 0) {
      this.callCategoryToSave(this.categoriesSaver);
    }
  }

  callCategoryToSave(categories: any) {
    let iterator = 0;

    const processCategory = () => {
      if (iterator <= categories.length - 1) {
        this.postCategory(categories[iterator], iterator);
        iterator++;

        // Aguarda 1 segundo antes de chamar a próxima iteração
        setTimeout(processCategory, 1000);
      }
    };

    // Inicia o processo
    processCategory();
  }

  callNextCategoryToSave(iteratorCurrent: number, categoryListTosave: any) {
    console.log('iteratorCurrent ', iteratorCurrent);
    console.log('categoryListTosave ' + JSON.stringify(categoryListTosave));
    console.log('this.categoryForm', this.categoryForm);

    if (iteratorCurrent <= categoryListTosave.length - 1) {
      this.postCategory(categoryListTosave[iteratorCurrent]);
    } else {
      // this.closeModal();
      this.iteratorSaver = 0;
    }
  }

  postCategory(params: any, interator: number = 0) {
    this.gradesService
      .postCategory(this.period?.id, params)
      .pipe(
        finalize(() => {
          this.gradesService.checkForUpdatesOnPeriod();
        })
      )
      .subscribe({
        next: (category: any) => {
          this.removeControlFormNewCategories(0);
          this.initFormArray([category]);
          this.closeModal('save');
        },
        error: (err) => {
          this.categoriesSaver = this.categoriesSaver.filter(
            (item: any, index: any) => {
              return index >= interator;
            }
          );
          this.categoryForm.controls['newCategories'] = this.FormBuilder.array(
            []
          );
          this.newCategoriesForm(this.categoriesSaver);
          this.iteratorSaver = 0;
          if (err.status == 409) {
            this.platModalService.toggle(
              'message',
              this.i18n.grades_weight_limit_exceed,
              'close'
            );
          } else {
            this.platModalService.toggle('message', this.i18n[err.error.error], 'close');
          }
        },
      });
  }

  newCategoriesForm(arrayToCreateForm: any) {
    console.log('arrayToCreateForm ', arrayToCreateForm);

    const formArray = this.newCategories;
    arrayToCreateForm.map((item) => {
      formArray.push(this.createNewCategoryForm(item, false));
    });
    this.categoryForm.setControl('newCategories', formArray);
  }

  callNextCategoryToUpdate(iteratorCurrent: number, categoryListTosave: any) {
    if (iteratorCurrent <= categoryListTosave.length - 1) {
      this.patchCategory(categoryListTosave[iteratorCurrent]);
    } else {
      if (this.categoriesSaver.length > 0) {
        this.callCategoryToSave(this.categoriesSaver);
      } else {
        // this.closeModal()
      }
    }
  }

  isMaxWeightValid(): boolean {
    let categoriesWeight = this.formatCategoriesToCheckWeight();
    console.log('categoriesWeight ', categoriesWeight);

    let sumWeigth = 0
    if (categoriesWeight.length) {
      for (const objeto of categoriesWeight) {
        sumWeigth = sumWeigth + parseInt(objeto.weight);
        if (sumWeigth > 100) {
          return false;
        }
      }
    }
    return true;
  }

  formatCategoriesToUptade() {
    let category = this.categoryForm.controls['categories']['controls'];
    console.log('category ', category);
    return this.categoryForm.controls['categories']['controls'].map((element: any) => {
      // let category =
      //   this.categoryForm.controls['categories']['controls'][element?.index][
      //     'controls'
      //   ];
      const params = {
        id: element?.controls.id.value,
        name: element?.controls.name.value,
        weight: element?.controls.weight.value.toString()
        .replace('%', '')
        .replace(',', '.'),
      };

      return params;
    });
  }

  formatCategoriesToCheckWeight() {
    let category = this.categoryForm.controls['categories']['controls'];
    console.log('category ', category);
    let categories = this.categoryForm.controls['categories']['controls'].map((element: any) => {
      // let category =
      //   this.categoryForm.controls['categories']['controls'][element?.index][
      //     'controls'
      //   ];
      const params = {
        name: element?.controls.name.value,
        weight: element?.controls.weight.value.toString()
        .replace('%', '')
        .replace(',', '.'),
      };

      return params;
    });

    let newCategories = this.categoriesSaver.map((element: any) => {

      const params = {
        name: element?.name,
        weight: element?.weight.toString()
        .replace('%', '')
        .replace(',', '.'),
      };

      return params;
    });

    let arr: any = []
    arr = [...categories, ...newCategories]
    return arr;
  }

  patchCategory(categoryListTosave: any) {
    let updateThisCategories = this.formatCategoriesToUptade();

    this.gradesService
      .patchCategory(this.period.id, updateThisCategories)
      .pipe(
        finalize(() => {
          // this.iteratorSaver = 0;
          this.gradesService.checkForUpdatesOnPeriod();
        })
      )
      .subscribe({
        next: (category: any) => {
          categoryListTosave.forEach((element: any) => {
            this.editInput[element?.index] = undefined;
            this.categories.controls[element?.index]['controls'][
              'name'
            ].disable();
            this.categories.controls[element?.index]['controls'][
              'weight'
            ].disable();
          });
          // this.callNextCategoryToSave(this.iteratorSaver,this.categoriesSaver);
          this.closeModal('save');
        },
        error: (err) => {
          this.iteratorUpdater = 0;
          if (err.status == 409) {
            this.platModalService.toggle(
              'message',
              this.i18n.grades_weight_limit_exceed,
              'close'
            );
          } else {
            this.platModalService.toggle('message', this.i18n[err.error.error], 'close');
          }
        },
      });
  }

  deleteSettingsCategory(grade_category_id: any, index: number) {
    this.gradesService
      .deleteCategory(this.period?.id, grade_category_id)
      .subscribe({
        next: () => {
          this.gradesService.checkForUpdatesOnPeriod();
          this.removeControlFormCategories(index);
        },
        error: (err) => {
          this.platModalService.toggle('message', this.i18n[err.error.error], 'close');
        },
      });
  }

  removeControlFormCategories(index: number) {
    this.categories['controls'].splice(index, 1);
  }

  removeControlFormNewCategories(index: number) {
    this.newCategories.removeAt(index);
    this.categoryForm.updateValueAndValidity();
  }

  unsubscribeLists() {
    if (this.subscription.length) {
      this.subscription.forEach(element => {
        element.unsubscribe();
      });
    }
  }

  ngOnDestroy() {
    console.log('DESTROY new-category-form');
    this.unsubscribeLists();
  }

  areAllInputsFilled(): boolean {
    // Função auxiliar para verificar se um controle está preenchido
    const isControlFilled = (control: any) => {
      const name = control.get('name')?.value?.trim();
      const weight = control.get('weight')?.value?.toString().trim();
      return name && weight;
    };

    // Aplica a validação de acordo com o tipo de cálculo
    const controlsToCheck = [...this.categories.controls, ...this.newCategories.controls];

    return controlsToCheck.every(control => {
        return isControlFilled(control);
    });
  }

}
