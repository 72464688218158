import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { LoginService } from '../../login.service';

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.scss']
})
export class SSOLoginComponent implements OnInit {
    @Input() isVisible: boolean;
    isLoading: boolean;
    i18n: any = [];

    constructor(
        private loginService: LoginService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        const route = this.route.snapshot.queryParams;

        console.log('route ', route);

        if (Object.prototype.hasOwnProperty.call(route, 'code') && Object.prototype.hasOwnProperty.call(route, 'state')) {
            this.isLoading = true;
            this.authGoogle(route.code);
            return;
        }
        this.getTranslations();
        this.getAllowProvider();
    }

    getAllowProvider() {
        this.loginService.update_localstorage.subscribe((authentication) => {
            const allowedProviders = authentication?.allowedProviders;
            if (allowedProviders.length > 0) {
                const googleProvider = allowedProviders.find((item) => item.name === 'google');
                if (googleProvider !== undefined && Object.keys(googleProvider).length) {
                    this.getGoogleClientId();

                    const whoami = {
                        clientId: googleProvider.client_id,
                        apiKey: googleProvider.api_key,
                        appId: googleProvider.app_id,
                        mail: authentication?.backendAccount
                    };

                    this.loginService.setWhoamiCredencials(whoami);
                }
            }
        });
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Login'), ...this.sharedService.getTranslationsOf('Errors') };
    }

    private clientOptionSSO = {
        client_id: '',
        hosted_domain: '',
        state: ''
    };
    getGoogleClientId() {
        this.isLoading = true;
        const allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
        if (Array.isArray(allowedProviders) || allowedProviders !== undefined || allowedProviders !== '') {
            allowedProviders.forEach((element) => {
                if (element.name === 'sso' && element.client_id !== '' && element.client_id !== undefined) {
                    this.clientOptionSSO.client_id = element.client_id;
                    this.clientOptionSSO.hosted_domain = element.hosted_domain;
                    this.clientOptionSSO.state = element.state;
                }
            });
        } else {
            throw new Error('Plataform settings is undefined');
        }
        this.isLoading = false;
        this.isVisible = true;
    }

    loginWithSSO() {
        this.isLoading = true;
        let oAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth?';
        if (this.clientOptionSSO) {
            oAuthURL += 'response_type=code';
            oAuthURL += `&client_id=${encodeURIComponent(this.clientOptionSSO.client_id)}`;
            oAuthURL += `&redirect_uri=${encodeURIComponent(window.location.origin)}`;
            oAuthURL += `&scope=${encodeURIComponent('openid profile email')}`;
            oAuthURL += '&include_granted_scopes=true';
            oAuthURL += '&access_type=offline';
            oAuthURL += `&hd=${this.clientOptionSSO.hosted_domain}`;
            oAuthURL += `&state=${this.clientOptionSSO.state}`;
            location.href = oAuthURL;
            return;
        }

        throw new Error('Unable to perform google login. Plataform client_id is undefined');
    }

    checkGoogleUser(user: any) {
        if (user) {
            this.authGoogle(user);
        } else {
            localStorage.setItem('logOut', 'true');
        }
    }

    authGoogle(code: any) {
        this.isLoading = true;
        const params = {
            code
        };

        this.loginService.loginWithGoogle(params)
            .subscribe({
                next: (response) => {
                    // Se usuário não aceitou os termos, abre modal e encaminha objeto de
                    // resposta de
                    this.sharedService.storeUserCredentials(response);
                    if (response.terms !== null) {
                        this.loginService.authObject$.next(response);
                        this.loginService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.loginService.setGoogleCredencials(response);
                        this.sharedService.loggedIn.next(true);
                        this.loginService.isRedirectRoute();
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    if (err.status === 422) {
                        this.platModalService.toggle('message', this.i18n?.[err?.error?.key_error], 'close');
                    } else if (err.status === 404) {
                        this.platModalService.toggle('message', this.i18n?.error_not_profile_login, 'close');
                    } else {
                        this.platModalService.toggle('message', this.i18n?.error_system_error, 'close');
                    }

                    this.sharedService.loggedIn.next(false);
                },
                complete: () => { this.isLoading = false; }
            });
    }
}
