import {
    Component, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-info-status-delivery',
    templateUrl: './info-status-delivery.component.html',
    styleUrls: ['./info-status-delivery.component.scss']
})
export class InfoStatusDeliveryComponent implements OnChanges {
    @Input() content: any;
    currentDate: Date = new Date();
    statusText = '';
    status = '';
    user;
    i18n;

    hasDeliveries = ['UploadAssignment', 'DocsAssignment', 'SheetsAssignment', 'InternalQuiz', 'Forum', 'LTI'];

    constructor(
        private sharedService: SharedService,
        private localStorageService: LocalStorageService
    ) {
        this.user = sharedService.getUserSync();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.content) {
                this.getTranslations();
                this.getStatus();
            }
        }
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Upload');
    }

    getStatus(): void {
        const endDate = new Date(this.content.end_date);
        const courseWorkCompletedAt = new Date(this.content.coursework_completed_at);

        if (this.currentDate > endDate && this.content.status === 'PENDING') {
            if (!this.content.allow_late_submission) {
                if (this.hasDeliveries.includes(this.content?.type)) {
                    this.statusText = this.i18n.upload_not_allow_late_delivery_description;
                    this.status = 'not_allow_late_delivery';
                    return;
                }

                this.statusText = this.i18n.upload_not_allow_late_delivery_description_task;
                this.status = 'not_allow_late_delivery';
                return;
            }

            if (this.hasDeliveries.includes(this.content?.type)) {
                this.statusText = this.i18n.upload_allow_late_delivery_description;
                this.status = 'allow_late_delivery';
                return;
            }

            this.statusText = this.i18n.upload_allow_late_delivery_description_task;
            this.status = 'allow_late_delivery';
            return;
        }

        if (this.content.status === 'PENDING') {
            if (this.hasDeliveries.includes(this.content?.type)) {
                this.statusText = this.i18n.upload_delivery_on_time_description;
                this.status = 'delivery_on_time';
                return;
            }

            this.statusText = this.i18n.upload_delivery_on_time_description_task;
            this.status = 'delivery_on_time';
            return;
        }

        if (this.currentDate > endDate && this.content.status === 'COMPLETE') {
            if (!this.hasDeliveries.includes(this.content?.type)) {
                this.statusText = this.i18n.upload_late_delivery_description;
                this.status = 'late_delivery';
                return;
            }
        }

        if (this.content.status === 'COMPLETE') {           
            if (this.hasDeliveries.includes(this.content?.type)) {
                if (this.content.completion_rule === 'ACCESS') {
                    this.statusText = this.i18n.upload_completed_access_description;
                    this.status = 'completed';
                    return;
                } else {
                    this.statusText = this.i18n.upload_completed_description;
                    this.status = 'completed';
                    return;
                }
            }

            this.statusText = this.i18n.upload_completed_description_task;
            this.status = 'completed';
            if (courseWorkCompletedAt > endDate && this.content.allow_late_submission) {
                this.statusText = this.i18n.upload_late_delivery_description;
                this.status = 'late_delivery';
                return;
            }

            this.statusText = this.i18n.uploaded_delivery_on_time_description;
            this.status = 'late_delivery';
        }
    }

    getDateTime(date): string {
        let currentLanguage;
        const currentDate = new Date();
        const endDate = new Date(this.content.end_date);

        if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
            currentLanguage = localStorage.getItem('currentLanguage');
        }
        if (!currentLanguage) {
            currentLanguage = 'pt-BR';
        }

        if (!date) {
            return '';
        }

        const newHour = new Date(date).toLocaleTimeString(currentLanguage, {
            hour: '2-digit', minute: '2-digit'
        });
        const newDate = new Date(date).toLocaleDateString(currentLanguage, {
            year: 'numeric', month: '2-digit', day: '2-digit'
        });

        let deadlineExpired = '';

        // Check if the endDate task has passed
        if (
            currentDate.getTime() > endDate.getTime()
            && this.content.status === 'PENDING'
            && !this.content.allow_late_submission
        ) {
            deadlineExpired = this.i18n.upload_task_delivery_expired;
        }

        return `${deadlineExpired}${newDate} ${this.returnTimeDescription(date)} ${this.i18n.upload_hours} ${newHour.replace(':', 'h')}`;
    }

    returnTimeDescription(dateString: string): string {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        // Check if the date is today
        if (
            inputDate.getDate() === currentDate.getDate()
          && inputDate.getMonth() === currentDate.getMonth()
          && inputDate.getFullYear() === currentDate.getFullYear()
        ) {
            return `(${this.i18n.upload_today})`;
        }

        // Check if the date is tomorrow
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
        if (
            inputDate.getDate() === tomorrow.getDate()
          && inputDate.getMonth() === tomorrow.getMonth()
          && inputDate.getFullYear() === tomorrow.getFullYear()
        ) {
            return `(${this.i18n.upload_tomorrow})`;
        }

        // Check if the date is after tomorrow
        const afterTomorrow = new Date(currentDate);
        afterTomorrow.setDate(currentDate.getDate() + 2);
        if (
            inputDate.getDate() === afterTomorrow.getDate()
          && inputDate.getMonth() === afterTomorrow.getMonth()
          && inputDate.getFullYear() === afterTomorrow.getFullYear()
        ) {
            return `(${this.i18n.upload_after_tomorrow})`;
        }

        return '';
    }
}
