/* eslint-disable no-restricted-globals */
import { formatDate } from '@angular/common';
import {
    Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { Calendar } from 'primeng/calendar';

import { ClassDiaryService } from '../../class-diary.service';

@Component({
    selector: 'app-calendar-picker',
    templateUrl: './calendar-picker.component.html',
    styleUrls: ['./calendar-picker.component.scss']
})
export class CalendarPickerComponent implements OnInit {
    @ViewChild('calendarDate') selectorcalendarEndDate: Calendar;

    @Input() currentLanguage: string | null;
    @Input() position = 'initial';
    @Input() minDateValidation: any;
    @Input() enableCalendar = false;
    @Input() inputDate: string;
    @Input() frequencyCalendar: any;
    @Output() emitEvent = new EventEmitter();
    @Output() emitGetFrequencyCalendar = new EventEmitter();

    dateInputMask: any;
    date: Date | undefined;
    maxDateValidation: Date = new Date();
    daysOfweek;

    constructor(
        private classDairyService: ClassDiaryService
    ) {
        this.daysOfweek = classDairyService.daysOfWeek;
    }

    changeMonth(event) {
        console.log('changeMonth ', event);
        this.emitGetFrequencyCalendar.emit(event);
    }

    ngOnInit(): void {
        this.createDateMaskInput();
    }

    toggleCalendar() {
        this.enableCalendar = !this.enableCalendar;
    }

    createDateMaskInput() {
        this.dateInputMask = createMask<Date>({
            alias: 'datetime',
            inputFormat: this.currentLanguage === 'en' ? 'mm/dd/yyyy' : 'dd/mm/yyyy',
            placeholder: '',
            oncleared: () => {
                this.selectorcalendarEndDate.clear();
                this.typeToPickerDate('');
            },
            oncomplete: () => {
                this.typeToPickerDate(this.inputDate);
            }
        });
    }

    fnPickDateToInput(calendarDateValue) {
        if (calendarDateValue) {
            const formatedDate = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'yyyy-MM-dd', 'en-US') : formatDate(calendarDateValue, 'yyyy-MM-dd', 'pt-BR');
            const formatedDateToInput = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy', 'en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy', 'pt-BR');
            this.inputDate = formatedDateToInput;
            this.emitEvent.emit(formatedDate);
            this.enableCalendar = false;
        }
    }

    typeToPickerDate(value) {
        console.log('value ', value);

        if (value) {
            if (value === '') {
                const format = this.currentLanguage === 'en' ? formatDate(value, 'MM/dd/yyyy', 'en-US') : formatDate(value, 'dd/MM/yyyy', 'pt-BR');
                this.inputDate = format;
                return;
            }

            const toDate = this.classDairyService.convertStringToDate(value, this.currentLanguage);

            if (!Number.isNaN(toDate.getTime())) {
                const formatedDate = this.currentLanguage === 'en' ? formatDate(toDate, 'yyyy-MM-dd', 'en-US') : formatDate(toDate, 'yyyy-MM-dd', 'pt-BR');
                this.date = toDate;
                this.emitEvent.emit(formatedDate);
                this.enableCalendar = false;
            }
        }
    }

    getDayOfWeek(dateString: string): string {
        const date = this.classDairyService.convertStringToDate(dateString, this.currentLanguage);
        return this.classDairyService.getDayOfTheWeekTranslated(this.daysOfweek[date.getDay()]);
    }

    getStatusInfrequencyCalendar(day: number): boolean {
        if (this.frequencyCalendar) {
            const dayFound = this.frequencyCalendar.data.days.find((dayData) => dayData.day === day);

            if (dayFound) return dayFound.status;
            return false;
        }
        return false;
    }

    hasStatusFinished(day: number): boolean {
        return !!this.getStatusInfrequencyCalendar(day);
    }
}
