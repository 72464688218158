import { Injectable } from '@angular/core';

import { SharedService } from '../shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class TimeService {
    currentLanguage = 'pt-BR';

    constructor(
        private sharedService: SharedService
    ) {
        this.currentLanguage = this.sharedService.getSelectedLanguage();
    }

    // Funções Auxiliares
    public getTime(date: Date) {
        const { currentLanguage } = this;

        const MS_PER_HOUR = 1000 * 60 * 60;// Hora em milissegundos
        const now = new Date().getTime();
        const inDate = new Date(date).getTime();// Data que vem do banco

        const hours = Math.floor((now - inDate) / MS_PER_HOUR);// transforma em hora

        if (hours >= 24 && hours > 0 && hours < 48) {
            if (currentLanguage === 'pt-BR') {
                return `há ${Math.floor(hours / 24)} dia`;
            }
            if (currentLanguage === 'en') {
                return `${Math.floor(hours / 24)} day ago`;
            }
            if (currentLanguage === 'es') {
                return `hace ${Math.floor(hours / 24)} dia`;
            }
        } else if (hours >= 48) {
            if (currentLanguage === 'pt-BR') {
                return `há ${Math.floor(hours / 24)} dias`;
            }
            if (currentLanguage === 'en') {
                return `${Math.floor(hours / 24)} days ago`;
            }
            if (currentLanguage === 'es') {
                return `hace ${Math.floor(hours / 24)} dias`;
            }
        } else if (hours === 1) {
            if (currentLanguage === 'pt-BR') {
                return `há ${hours} hora`;
            }
            if (currentLanguage === 'en') {
                return `${hours} hour ago`;
            }
            if (currentLanguage === 'es') {
                return `hace ${hours} horas`;
            }
        } else if (hours === 0) {
            const minutes = Math.floor((now - inDate) / 60000);// transforma para minutos
            if (minutes === 0) {
                if (currentLanguage === 'pt-BR') {
                    return 'há poucos segundos';
                }
                if (currentLanguage === 'en') {
                    return 'few seconds ago';
                }
                if (currentLanguage === 'es') {
                    return 'hace unos segundos';
                }
            } else if (minutes === 1) {
                if (currentLanguage === 'pt-BR') {
                    return `há ${minutes} minuto`;
                }
                if (currentLanguage === 'en') {
                    return `${minutes} minute ago`;
                }
                if (currentLanguage === 'es') {
                    return `hace ${minutes} minutos`;
                }
            } else {
                if (currentLanguage === 'pt-BR') {
                    return `há ${minutes} minutos`;
                }
                if (currentLanguage === 'en') {
                    return `${minutes} minutes ago`;
                }
                if (currentLanguage === 'es') {
                    return `hace ${minutes} minutos`;
                }
            }
        } else {
            if (currentLanguage === 'pt-BR') {
                return `há ${hours} horas`;
            }
            if (currentLanguage === 'en') {
                return `${hours} hours ago`;
            }
            if (currentLanguage === 'es') {
                return `hace ${hours} horas`;
            }
        }

        return '';
    }

    getDateTime(date): string {
        const { currentLanguage } = this;

        if (!date) {
            return '';
        }

        const newHour = date.split(' ')[1];
        const newDate = new Date(date).toLocaleDateString(currentLanguage, { year: 'numeric', month: 'numeric', day: 'numeric' });
        return `${newDate} #$ ${newHour}`;
    }

    formatDateTimeByLanguage(date: string): string {
        const { currentLanguage } = this;

        const newHour = date.split(' ')[1];
        const newDate = new Date(date).toLocaleDateString(currentLanguage, { year: 'numeric', month: 'numeric', day: 'numeric' });

        const [hour] = newHour.split(':').map(Number);
        const minuteString = newHour.split(':')[1];
        const isPM = hour >= 12;
        const converTo12HourFormat = (timeNumber: number) => (timeNumber % 12 === 0 ? 12 : timeNumber % 12);

        const formattedHour = currentLanguage === 'en' ? converTo12HourFormat(hour) : hour;

        let suffix = '';
        if (currentLanguage === 'en') {
            suffix = isPM ? 'PM' : 'AM';
        }

        if (suffix !== '') {
            return `${newDate} #$ ${formattedHour.toString().padStart(2, '0')}h${minuteString} ${suffix}`;
        }

        return `${newDate} #$ ${formattedHour.toString().padStart(2, '0')}h${minuteString}`;
    }
}
