<section class="min-h-screen flex items-center justify-center p-4 w-full">
  <!-- Valid  -->
  <div
    class="rounded bg-customWhite-default shadow-md w-full sm:w-10/12 md:w-10/12 lg:w-2/3 xl:w-2/5 animation-card"
  >
  <div *ngIf="!loading">
    <header
      class="flex flex-col gap-4 items-center p-6 md:p-10 pb-3 md:pb-5 border-b border-dashed border-customBlue-default"
    >
      <div class="flex flex-col sm:flex-row items-center gap-4">
        <span
          class="iconify-inline text-40 text-customGreen-default"
          data-icon="ic:round-check-circle"
        ></span>
        <h1 class="text-customBlue-default text-18 sm:text-30 font-semibold">
          {{ i18n.certificates_validator_valid_certificate }}
        </h1>
      </div>
    </header>
    <div
      class="p-6 md:p-10 pt-3 md:pt-5 flex flex-col items-center gap-6 text-customGray-dark"
    >
      <span>{{ i18n.certificates_validator_data_certificate }}:</span>
      <div class="flex flex-col gap-1 text-14 break-all">
        <p>
          <span class="font-medium"
            >{{ i18n.certificates_validator_name_student }}:</span
          >
          {{ validData?.strings?.student_name }}
        </p>
        <p>
          <span class="font-medium"
            >{{ i18n.certificates_validator_discipline }}:</span
          >
          {{ validData?.strings?.discipline_name }}
        </p>
        <p>
          <span class="font-medium"
            >{{ i18n.certificates_validator_hourly_load }}:</span
          >
          {{ validData?.strings?.discipline_duration }}
        </p>
        <p>
          <span class="font-medium"
            >{{ i18n.certificates_validator_issue_date }}:</span
          >
          {{ validData?.strings?.emission_date }}
        </p>
        <a [href]="validData?.download_link" target="_blank">
            <span class="font-medium">{{ i18n.certificates_validator_verification_link }}: </span>
            <span class="underline">{{ validData?.download_link }}</span>
        </a>  
      </div>
      <button
        class="button-save py-2 h-auto w-max px-10 text-customWhite-default"
        type="button"
        (click)="backToValidator()"
      >
        {{ i18n.certificates_validator_verify_other }}
      </button>
    </div>
  </div>
  <div *ngIf="loading">
    <app-bullets-loader></app-bullets-loader>
  </div>
  </div>
</section>
<app-message-modal></app-message-modal>
