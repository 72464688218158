<section>
  <div>
    <h1 class="screen-title">{{ i18n.disciplines_templates }}</h1>
  </div>
  <div class="page-header">
    <div class="justify-start mr-auto message-import" *ngIf="isImporting">
      <span class="iconify icon-message-import" data-icon="jam:alert-f"></span>
      <span>{{ messageImporting }}</span>
    </div>
    <div class="flex flex-col sm:flex-row gap-1.5 ml-auto">
      <form [formGroup]="searchForm" class="search-form">
        <div class="input-search-container">
          <label for="search">
            <img (click)="searchDiscipline()" />
          </label>
          <input
            formControlName="query"
            id="search"
            class="input-search"
            [placeholder]="i18n.disciplines_search_for_models"
            (keyup)="searchDiscipline()"
          />
        </div>
      </form>

      <div class="flex gap-1.5">
        <div
          *ngIf="user.role.external_id !== 'student'"
          class="filter-button-wrapper"
        >
          <button (click)="filterOpen = !filterOpen" class="filter-button">
            <img
              *ngIf="!filterOpen"
              src="../../../assets/svg/filter-black.svg"
            />
            <img
              *ngIf="filterOpen"
              src="../../../assets/svg/filter-orange.svg"
            />
          </button>
        </div>
        <div class="app-plus-container">
          <app-plus-button
            *ngIf="user.role.external_id !== 'student'"
            (click)="openModalAdd()"
          ></app-plus-button>
        </div>

        <div class="app-import-classroom flex-col-button" *ngIf="showClassroomImport">
          <button
            *ngIf="user.role.external_id !== 'student'"
            class="p-4 button-card"
            style="font-size: 16px;"
            (click)="
              getDisciplinesToModalClassroom($event); openModalClassroomImport()
            "
          >
            {{ i18n.disicplines_title_import_classroom }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="filterOpen" class="options-filter" [formGroup]="searchForm">
    <div class="text-customGray-dark">
      <label class="mb-1">{{ i18n.disciplines_segments }}</label>
      <select class="filter-select-option" formControlName="segmentFilter">
        <option [value]="selectUndefinedOptionValue">
          {{ i18n.disciplines_segments_all }}
        </option>
        <option
          *ngFor="let segment of segmentSelect"
          [value]="segment.external_id"
          [ngValue]="segment.external_id"
        >
          {{ translationsSegment(segment.external_id, segment.name) }}
        </option>
      </select>
    </div>
    <div class="text-customGray-dark">
      <label class="mb-1">{{ i18n.disciplines_filter_modalities }}</label>
      <select class="filter-select-option" formControlName="modalityFilter">
        <option [value]="selectUndefinedOptionValue">
          {{ i18n.disciplines_filter_all_modalities }}
        </option>
        <option
          *ngFor="let modalitie of modalitiesSelect"
          [value]="modalitie.external_id"
          [ngValue]="modalitie.external_id"
        >
          {{ translationsModality(modalitie.external_id, modalitie.name) }}
        </option>
      </select>
    </div>
    <div class="text-customGray-dark">
      <label class="mb-1">{{ i18n.disciplines_filter_years }}</label>
      <select class="filter-select-option" formControlName="yearsFilter">
        <option [value]="selectUndefinedOptionValue">
          {{ i18n.disciplines_filter_all_years }}
        </option>
        <option *ngFor="let year of yearsSelect" [ngValue]="year">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="text-customGray-dark">
      <label class="mb-1">{{ i18n.disciplines_filter_months }}</label>
      <select class="filter-select-option" formControlName="monthsFilter">
        <option [value]="selectUndefinedOptionValue">
          {{ i18n.disciplines_filter_all_months }}
        </option>
        <option *ngFor="let month of monthsSelect" [ngValue]="month">
          {{ month }}
        </option>
      </select>
    </div>
    <div class="flex text-center relati">
      <button class="button-filter" (click)="filterDiscipline()">
        Filtrar
      </button>
    </div>
  </div>

  <div class="discipline-card-container" *ngIf="loading == false">
    <!-- MODEL NOT FOUND -->
    <h3
      class="text-xl font-bold text-customBlue-default"
      *ngIf="!dataDisciplines.length"
    >
      {{ i18n.disciplines_no_class_found }}
    </h3>

    <div class="card-discipline" *ngFor="let discipline of dataDisciplines">
      <app-card-discipline
        [disciplineTitle]="discipline.title"
        [disciplineCode]="discipline.external_id"
        [i18n]="i18n"
        [discipline]="discipline"
        (callGetSections)="getSections($event)"
        (callingRemoveTemplate)="remove($event, false)"
        (sendingDisciplineCode)="selectedDisciplineToImport($event)"
        (callingDuplicateTemplate)="verifyPreRegisteredDatesToPostDuplicate($event)"
        [enableImportClassroom]="showClassroomImport"
      ></app-card-discipline>
    </div>
  </div>

  <div
    class="flex justify-end mt-8 pagination-container"
    *ngIf="dataDisciplines?.length"
  >
    <app-pagination
      #pagination
      [params]="paginationParams"
      (navigation)="goToPage($event)"
    ></app-pagination>
  </div>
</section>

<app-modal-add-discipline
  *ngIf="showAddDiscipline"
  (sendingDisciplineData)="receivesDiscipline($event)"
  (visibleChange)="closeModalAdd($event)"
  [modalitiesSelect]="modalitiesSelect"
  [dataDisciplines]="dataDisciplines"
  [segmentSelect]="segmentSelect"
  [orgUnits]="orgUnits"
  [i18n]="i18n"
></app-modal-add-discipline>

<app-export-contents-modal
    #exportContentsModal
    *ngIf="isExportContentsModalOpen$ | async"
    (sendDisciplineData)="receivesExportDataToReplicate($event)"
    (sendingImportTemplate)="receivesImportTemplate($event)"
    (getDisciplines)="getDisciplinesToModal($event)"
    [isItInTheTemplates]="true"
    [disciplinesRawData]="disciplnesToModal"
    [disciplineCode]="disciplineCode"
    [sections]="sections"
    [i18n]="i18n"
    (getSectionsByDiscipline)="getSectionsByDisciplines($event)"
    (getSectionsTemplate)="getTemplatesSections()"
    (sendDatesResourcesValues)="getDatesResourcesValues($event)"
    [templateMode]="templateMode"
    [(isDuplicate)]="isDuplicate"
></app-export-contents-modal>

<app-duplicate-discipline
    *ngIf="isDuplicateDisciplineOpen$ | async"
    [(templateMode)]="templateMode"
    [i18n]="i18n"
    [disciplineExternalId]="disciplineExternalId"
    (emitPostDuplicateDiscipline)="postDuplicateDiscipline($event)" 
    (sendDatesResourcesValues)="getDatesResourcesValues($event)"

></app-duplicate-discipline>


<app-import-classroom-modal
  *ngIf="isImportModelClassroom$ | async"
  (sendingDisciplineData)="receivesImportClassroom($event)"
  (sendingExportDisciplineData)="receivesExport($event)"
  (getDisciplines)="getDisciplinesToModal($event)"
  (sendDatesResourcesValues)="getDatesResourcesValues($event)"
  [isItInTheTemplates]="isselectedExportOrImport"
  [disciplinesRawData]="disciplnesToModal"
  [disciplineCode]="disciplineCode"
  [sections]="sections"
  [i18n]="i18n"
>
</app-import-classroom-modal>

<ng-template ngIf="isModalConfirmClassroomOpen">
    <app-modal-confirm-classroom
        [isVisible]="isModalConfirmClassroomOpen"
        [btnText]="i18n.disciplines_modal_confirmation_back"
        [message]="messageModalConfirmClassroom"
        (closeEmit)="closeModalConfirmClassroom($event)"
    ></app-modal-confirm-classroom>
</ng-template>

<!-- <div *ngIf="showAddDiscipline" class="fixed inset-0 opacity-25 z-19" style="background-color: #000;"></div>  -->
