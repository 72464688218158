import { NgModule } from '@angular/core';

import { UploadComponent } from './upload.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { MessageModalModule } from '../modals/message-modal/message-modal.module';
import { DecisionModalModule } from '../modals/decision-modal/decision-modal.module';
import { GenericModalModule } from '../modals/generic-modal/generic-modal.module';



@NgModule({
  declarations: [
    UploadComponent,
  ],
  exports: [
    UploadComponent
  ],
  imports: [
    SharedModule,
    DecisionModalModule,
    MessageModalModule,
    GenericModalModule
  ]
})
export class UploadModule {}
