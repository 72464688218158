import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';

import { HomeLayoutComponent } from '../home/home.component';
import { ImportSpreadsheetComponent } from './import-spreadsheet.component';
import { ImportSpreadsheetGuard } from './import-spreadsheet.guard';
import { ImportSpreadsheetResolver } from './import-spreadsheet-title.resolver';
import { PreviewSheetComponent } from './preview-sheet/preview-sheet.component';
import { SelectSheetComponent } from './select-sheet/select-sheet.component';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuard],
        resolve: {
            title: ImportSpreadsheetResolver
        },

        children: [
            {
                path: '',
                component: ImportSpreadsheetComponent,
                canActivate: [ImportSpreadsheetGuard]
            },
            {
                path: 'select-sheet/:type',
                component: SelectSheetComponent,
                canActivate: [ImportSpreadsheetGuard]
            },
            {
                path: 'preview',
                component: PreviewSheetComponent,
                canActivate: [ImportSpreadsheetGuard]
            },
            {
                path: 'preview/:external_id',
                component: PreviewSheetComponent,
                canActivate: [ImportSpreadsheetGuard]
            }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ImportSpreadsheetRoutingModule { }
