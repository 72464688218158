import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';

export function collapseAnimation(duration: string) {
    return trigger('collapse', [
        state(
            'true',
            style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
        ),
        state(
            'false',
            style({ height: '0px', visibility: 'hidden', opacity: 0 })
        ),
        transition('false => true', animate(`${duration}ms ease-out`)),
        transition('true => false', animate(`${duration}ms ease-in`)),
    ]);
}

export function rotatedStateAnimation(duration: string) {
    return trigger('rotatedState', [
        state('false', style({ transform: 'rotate(0)' })),
        state('true', style({ transform: 'rotate(-180deg)' })),
        transition('false => true', animate(`${duration}ms ease-out`)),
        transition('true => false', animate(`${duration}ms ease-in`)),
    ]);
}

export function slideInOutAnimation(duration: string) {
    return trigger('slideInOut', [
        state('hidden', style({ transform: 'translateX(100%)', opacity: 0, display: 'none' })),
        state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('hidden => visible', [animate(`${duration}ms ease-out`)]),
        transition('visible => hidden', [animate(`${duration}ms ease-out`)]),
    ]);
}
