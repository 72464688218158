<ng-container *appCheckPermissions="'frequency'; type: 'create'">
    <div >
        <button class="button-class-diary" (click)="toggleClassDiary()">
            <div>
                <span
                    class="iconify w-10 h-10 text-customWhite-default"
                    data-icon="radix-icons:calendar"
                ></span>
            </div>
        </button>

        <div
            class="class-diary-container"
            [@slideInOut]="isClassDiaryOpen ? 'visible' : 'hidden'"
        >
            <div class="flex items-center justify-between pl-7 pr-2 py-4">
                <div
                    class="text-customBlue-default font-Montserrat font-bold text-16"
                >
                    {{ i18n.class_diary }}
                </div>

                <div class="cursor-pointer" (click)="handleCloseSidebar()">
                    <span
                        class="iconify w-5 h-5 text-customGray-dark"
                        data-icon="ion:close-circle-outline"
                    ></span>
                </div>
            </div>

            <hr class="text-customBlue-glass" />

            <div class="registers-container grid grid-cols-1 md:grid-cols-2">
                <div class="border-r border-customBlue-glass">
                    <app-class-registers
                        *ngIf="classDiaryData"
                        [classDiaryData]="classDiaryData"
                        [frequencyCalendar]="frequencyCalendar"
                        (studentsOcorrences)="receiveModalObject($event)"
                        (updateDate)="receiveUpdateDate($event)"
                        (updateClassScheduleDetail)="
                            receiveClassScheduleDetail($event)
                        "
                        (textInputs)="receiveTextInputs($event)"
                    ></app-class-registers>
                </div>

                <div>
                    <app-frequency
                        (attendStatus)="receiveAttendStatus($event)"
                        [classDiaryData]="classDiaryData"
                        (userSelected)="receiveModalObject($event)"
                    ></app-frequency>
                </div>
            </div>

            <hr class="text-customBlue-glass" />

            <div>
                <div class="flex justify-center mt-4">
                    <div
                        class="grid grid-cols-3 items-center justify-between gap-2 mt-1 border border-customBlue-glass rounded-md p-2"
                    >
                        <div
                            class="flex flex-col items-center justify-center text-customGray-dark"
                        >
                            <div class="font-bold text-14">
                                {{ i18n.class_diary_present }}
                            </div>
                            <div class="flex items-center justify-center gap-1">
                                <div class="icon-present icon-20"></div>
                                <div class="text-customGray-swampGray">
                                    {{ this.calcPercentByStatus("present") }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center justify-center text-customGray-dark"
                        >
                            <div class="font-bold text-14">
                                {{ i18n.class_diary_half_present }}
                            </div>
                            <div class="flex items-center justify-center gap-1">
                                <div class="icon-neutral icon-20"></div>
                                <div class="text-customGray-swampGray">
                                    {{
                                        this.calcPercentByStatus("1/2_presence")
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center justify-center text-customGray-dark"
                        >
                            <div class="font-bold text-14">
                                {{ i18n.class_diary_absent }}
                            </div>
                            <div class="flex items-center justify-center gap-1">
                                <div class="icon-absent icon-20"></div>
                                <div class="text-customGray-swampGray">
                                    {{ this.calcPercentByStatus("absent") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center md:justify-end mt-4 mb-6 px-6 gap-6">
                    <button
                        class="border border-customBlue-default bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-12 px-6 py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                        style="width: 152px"
                        type="button"
                        (click)="save()"
                        [ngClass]="{
                            'disabled:opacity-20 cursor-not-allowed':
                                classDiaryData?.frequency?.class_schedule_detail?.id === null || !classDiaryData?.frequency_users.length
                        }"
                        [disabled]="classDiaryData?.frequency?.class_schedule_detail?.id === null || !classDiaryData?.frequency_users.length"
                    >
                        {{ i18n.class_diary_finish }}
                    </button>
        
                    <button
                        (click)="cancel()"
                        class="text-customBlue-default border border-customBlue-default rounded-md background-transparent font-semibold px-6 py-2 text-12 outline-none focus:outline-none ease-linear transition-all duration-150"
                        style="width: 152px"
                        type="button"
                    >
                        {{ i18n.class_diary_close }}
                    </button>
                </div>
            </div>

            
        </div>
    </div>
</ng-container>

<app-modal-student-registers
    *ngIf="isModalStudentRegistersOpen$ | async"
    [reasonsArray]="reasonsArray"
    [modalObject]="modalObject"
    (sendModalObject)="receiveClassModalObject($event)"
></app-modal-student-registers>
