import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class ContentPlannerTitleResolver implements Resolve<void> {
    constructor(private sharedService: SharedService, private titleService: TitlePageService) { }
    resolve(route: ActivatedRouteSnapshot): void {
        const i18n = this.sharedService.getTranslationsOf('ContentPlanner');

        const paths = [{ url: '', title: i18n.planner_content_planning }];

        this.titleService.resolveTitle(route, paths);
    }
}
