import {
    Component, EventEmitter, Input, OnChanges, Output,
    SimpleChanges
} from '@angular/core';

type Status = 'present' | 'absent' | '1/2_presence' | '3/4_presence';

@Component({
    selector: 'app-attend-status',
    templateUrl: './attend-status.component.html',
    styleUrls: ['./attend-status.component.scss']
})
export class AttendStatusComponent implements OnChanges {
    @Input() status: Status = 'present';
    @Input() isDisabled = true;
    @Input() userExternalId: string;
    @Output() statusChange = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            // console.log('changes ', changes);
        }
    }
    handleChangeStatus(status: Status) {
        switch (status) {
            case 'present':
                this.status = '1/2_presence';
                break;
            case '1/2_presence':
                this.status = 'absent';
                break;
            case 'absent':
                this.status = 'present';
                break;
            default:
                this.status = 'present';
                break;
        }

        this.statusChange.emit({ status: this.status, externalId: this.userExternalId });
    }
}
