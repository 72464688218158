<app-form-modal
    [title]="i18n.question_bank_select_questions"
    [description]="i18n.question_bank_create_questionnaire_description"
    [isVisible]="true"
    class="pick-questions"
>
    <div class="pick-questions-container" [class.is-visible]="bankInitiated">
        <!-- ATENTION!!! :host it's styled -->
        <header class="header" [class.is-visible]="!emptyBank && bankInitiated">
            <div class="filters">
                <div class="search-bar">
                    <input
                        #searchInputElement
                        [(ngModel)]="searchTextInput"
                        class="search-bar__input"
                        [class.is-active]="searchTextInput.length"
                        [placeholder]="i18n.question_bank_search_by_question"
                        spellcheck="false"
                        autocomplete="false"
                    />
                    <i class="magnifier-icon icon"></i>
                </div>

                <div
                    class="search-tags"
                    *ngIf="allTags.length"
                    [class.is-active]="tagsSugestionExpanded"
                >
                    <input
                        [(ngModel)]="searchTagsInput"
                        class="search-tags__input"
                        [placeholder]="i18n.question_bank_search_by_tag"
                        spellcheck="false"
                        autocomplete="false"
                        (input)="generateTagsSugestions()"
                        (click)="generateTagsSugestions()"
                        [class.is-active]="tagsSugestionExpanded"
                    />
                    <i class="tag-icon icon"></i>

                    <div
                        #tagSugestion
                        class="search-tags__sugestions"
                        [class.is-invisible]="!tagsSugestionExpanded"
                    >
                        <div
                            class="sugestions__sugestion"
                            *ngFor="let tag of sugestionTags; let index = index"
                            (click)="selectTag(tag, index)"
                        >
                            <i class="tag-icon icon"></i>
                            {{ tag.text }}
                        </div>
                    </div>
                </div>

                <div
                    #pipeDropdown
                    class="pipe-filter"
                    [class.is-expanded]="pipeDropdownExpanded"
                >
                    <button
                        class="pipe-filter__button"
                        [class.is-active]="FiltersOn"
                        (click)="toggleDropdown('pipe')"
                        [title]="i18n.question_bank_filters"
                    >
                        <i class="filter-outline-icon pipe-filter__icon"></i>
                    </button>

                    <div class="overlay">
                        <div class="button-wrapper"></div>

                        <div class="pipe-filters__filters">
                            <div class="pipe-filters__filter dates">
                                <div class="filter__name">
                                    {{ i18n.question_bank_date }}
                                </div>

                                <app-single-choice-dropdown
                                    [items]="dates"
                                    [block]="true"
                                    (change)="changeSorting($event)"
                                ></app-single-choice-dropdown>
                            </div>

                            <div class="pipe-filters__filter types">
                                <div class="filter__name">
                                    {{ i18n.question_bank_type }}
                                </div>

                                <div class="multiple-choice">
                                    <div
                                        class="type"
                                        *ngFor="
                                            let type of types;
                                            let i = index
                                        "
                                        [class.is-active]="type.active"
                                        (click)="
                                            type.active = !type.active;
                                            refreshQuestionsWithoutScroll();
                                            refreshFilterTracking()
                                        "
                                    >
                                        <app-check-button
                                            [iconSize]="16"
                                            [active]="type.active"
                                            [label]="type.translation"
                                        ></app-check-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="selected-tags-wrapper" *ngIf="selectedTags.length">
            <div class="selected-tags-title">
                <i class="tag-icon icon"></i>
                {{ i18n.question_bank_tags }}
            </div>

            <div class="selected-tags">
                <div
                    class="selected-tags__tag"
                    *ngFor="let tag of selectedTags; let index = index"
                >
                    {{ tag.text }}
                    <i class="x-icon icon" (click)="unselectTag(index)"></i>
                </div>
            </div>
        </div>

        <div class="text-divisor">
            {{ i18n.question_bank_question }}
            <!-- <div class="text-divisor__highlight">
                {{ selectedQuestions.length }}
            </div> -->
        </div>

        <div 
            class="questions"
            *ngIf="questions.length"
        >
            <app-question-bank-question
                class="questions__question"
                *ngFor="let question of questions; let index = index"
                #questionComponent
                [data]="question"
                [noEdit]="true"
                [noDelete]="true"
                [noSelect]="false"
                [selected]="question.selected"
                (onSelect)="selectQuestion($event, question, index)"
                (likeQuestion)="likeQuestion($event, question)"
            >
            </app-question-bank-question>
        </div>

        <app-question-bank-question [data]="noQuestionData" class="no-question">
        </app-question-bank-question>

        <app-pagination
            #pagination
            class="pagination"
            *ngIf="bankInitiated && !noQuestionsFound && !emptyBank"
            [params]="paginationParams"
            (navigation)="goToPage($event)"
        ></app-pagination>

        <div
            class="aqui questions__not-found"
            *ngIf="noQuestionsFound && !emptyBank"
        >
            <div>
                <span
                    class="iconify w-20 h-20 text-customBlue-default"
                    data-icon="iconoir:file-not-found"
                ></span>    
            </div>

            {{ i18n.question_bank_search_results_not_found }}
        </div>

        <div class="decision-btns center text-16">
            <div
                class="btn forward text-16"
                (click)="
                    addQuestions.emit(this.selectedQuestions); close.emit()
                "
            >
                {{ i18n.question_bank_continue }}
            </div>
            <div class="btn backward text-16" (click)="close.emit()">
                {{ i18n.question_bank_cancel }}
            </div>
        </div>
    </div>
</app-form-modal>
