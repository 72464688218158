import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';

import { HomeLayoutComponent } from '../home/home.component';
import { SportsComponent } from './sports.component';
import { SportsGuard } from './sports.guard';
import { SportsTitleResolver } from './sports-title.resolver';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuard],
        resolve: {
            title: SportsTitleResolver
        },
        children: [
            {
                path: '',
                component: SportsComponent,
                canActivate: [SportsGuard],
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SportsRoutingModule { }
