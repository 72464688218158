import {
    Component, EventEmitter, Input, OnChanges, Output
} from '@angular/core';
import { GoogleChatService } from 'src/app/services/google-chat.service';

@Component({
    selector: 'app-modal-send-message',
    templateUrl: './modal-send-message.component.html',
    styleUrls: ['./modal-send-message.component.scss'],
})

export class ModalSendMessageComponent implements OnChanges {
    @Input() isVisibleModalSend = false;
    @Input() i18n: any = {};
    @Input() type: type;
    @Input() userList: any[] = [];
    @Input() userLogged: any = {};

    @Output() update = new EventEmitter();
    @Output() close = new EventEmitter();

    title = '';
    subtitle = '';

    selectedUser: any[] = [];
    masterSelected = false;
    isSelected = false;

    listUserFiltered: any[] = [];
    _filterBy = '';

    constructor(private googleChatService: GoogleChatService) { }

    ngOnChanges(): void {
        if (this.isVisibleModalSend) {
            this.handleTitle(this.type);
            this.userList.map((user: any) => { user.isSelected = this.isSelected; });
            this.listUserFiltered = this.userList;
        }
    }

    handleTitle(type: type): void {
        if (type === 'mail') {
            this.title = this.i18n.disciplines_send_gmail;
            this.subtitle = this.i18n.disciplines_modal_send_email_description;
        } else {
            this.title = this.i18n.disciplines_send_chat;
            this.subtitle = this.i18n.disciplines_modal_send_chat_description;
        }
    }

    closeModal(type: type): void {
        this.close.emit(type);
        setTimeout(() => {
            this.reset();
        }, 1000);
    }

    submit(): void {
        const email: string[] = [];
        this.selectedUser.forEach((user) => { email.push(user.email); });

        if (this.type === 'mail') {
            this.openEmailBox(email);
        } else {
            this.googleChatService.requestChatScope(email);
        }

        this.closeModal(this.type);
    }

    openEmailBox(email: string[]): void {
        const w = 650;
        const h = 600;
        const l = Number(screen.width / 2 - w / 2);
        const t = Number(screen.height / 3 - h / 3);

        const features = `location=yes, scrollbars=yes, status=yes, resizable=no, width=${w}, height=${h}, top=${t}, left=${l}`;
        const url = `https://mail.google.com/mail/?view=cm&fs=1&su=&body=&bcc=${email}&authuser=${this.userLogged?.email}`;

        window.open(url, '_blank', features);
    }

    checkUncheckAll() {
        this.masterSelected = !this.masterSelected;

        this.listUserFiltered.forEach((user, i) => {
            if (user.email !== this.userLogged?.email) {
                this.listUserFiltered[i].isSelected = this.masterSelected;
                this.isAllSelected(user, this.masterSelected);
            }
        });
    }

    isAllSelected(user, boolean) {
        const index = this.selectedUser.findIndex((savedUser) => savedUser.external_id === user.external_id);

        if (boolean && index === -1) {
            this.selectedUser.push(user);
        } else if (!boolean && index !== -1) {
            this.selectedUser.splice(index, 1);
        } else if (boolean && index !== -1) {
            this.selectedUser[index] = user;
        }
    }

    reset(): void {
        this.selectedUser = [];
        this.masterSelected = false;
        this.isSelected = false;
        this._filterBy = '';
    }

    set search_student(value: string) {
        if (value) {
            this._filterBy = value;
            this.listUserFiltered = this.userList
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .filter(
                    (name) => name.name.toLowerCase().indexOf(value.toLocaleLowerCase()) > -1
                );
        } else {
            this.listUserFiltered = this.userList;
        }
    }

    get search_student() {
        return this._filterBy;
    }
}

type type = 'mail' | 'chat';
