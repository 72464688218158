import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';

import { ClassDiaryService } from '../../class-diary.service';

@Component({
    selector: 'app-modal-student-registers',
    templateUrl: './modal-student-registers.component.html',
    styleUrls: ['./modal-student-registers.component.scss']
})
export class ModalStudentRegistersComponent implements OnChanges {
    @Input() modalObject;
    @Input() reasonsArray;

    @Output() sendModalObject = new EventEmitter();

    studentRegistersForm: FormGroup;
    registerType = '';
    userEvents: any = [];
    frequency_users: any = [];
    selectedOption;

    isOpen = false;
    studentSelected: any | null = null;

    i18n;
    constructor(
        private fb: FormBuilder,
        private classDiaryService: ClassDiaryService,
        private elementRef: ElementRef,
    ) {
        this.i18n = classDiaryService.i18n;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.modalObject) {
                this.registerType = this.getTypeModal();

                // Received from type occurrences that is not have any selected User
                if (this.modalObject.frequency_users) {
                    this.frequency_users = this.modalObject.frequency_users;
                }

                // Received from type absence_note that have already selected User
                if (this.modalObject.userSelected) {
                    this.userEvents = this.modalObject.userSelected.frequency_user.events[this.registerType];
                    this.studentSelected = this.modalObject.userSelected.frequency_user;
                    this.frequency_users = [this.modalObject.userSelected.frequency_user];
                    this.modalObject.frequency_user = this.modalObject.userSelected.frequency_user;
                }

                this.createForm();

                if (this.userEvents) {
                    this.setValuesForm(this.userEvents);
                }

                this.getTitleAndSubtitle(this.registerType);
            }
        }
    }

    title = '';
    subtitle = '';
    getTitleAndSubtitle(type) {
        this.title = type === 'occurrence' ? this.i18n.class_diary_occurrence : this.i18n.class_diary_absent_reason;
        this.subtitle = type === 'occurrence' ? this.i18n.class_diary_occurrence_subtitle : this.i18n.class_diary_absent_reason_subtitle;
    }

    getReasonsTranslate(externalId: string): string {
        return this.classDiaryService.getReasonsTranslate(externalId);
    }

    getTypeModal():string {
        if (!Object.prototype.hasOwnProperty.call(this.modalObject, 'type')) {
            return '';
        }
        return this.modalObject.type === 'occurrences' ? 'occurrence' : 'absence';
    }

    createForm() {
        this.studentRegistersForm = this.fb.group(
            {
                reasons: this.fb.array([], Validators.required),
                note: [''],
            }
        );
    }

    get reasons() {
        return this.studentRegistersForm.get('reasons') as FormArray;
    }

    get note() {
        return this.studentRegistersForm.get('note');
    }

    setValuesForm(events) {
        if (events) {
            const note = events.note ? events.note : '';
            this.note?.setValue(note);

            if (events?.reasons?.length) {
                events.reasons.forEach((reason) => {
                    this.checkReason(reason);
                });
            }
        }
    }

    checkReason(reason) {
        const { reasons } = this;
        const scheduleIndex = reasons.controls.findIndex((control) => control.value === reason);

        if (scheduleIndex !== -1) {
            const index = reasons.controls.findIndex(
                (control) => control.value === reason
            );
            reasons.removeAt(index);
            return;
        }

        reasons.push(this.fb.control(reason));
    }

    cancel() {
        this.classDiaryService.isModalStudentRegistersOpen$.next(false);
    }

    save() {
        this.modalObject.frequency_user.events[this.registerType].note = this.note?.value;
        this.modalObject.frequency_user.events[this.registerType].reasons = this.reasons?.value;

        this.sendModalObject.emit(this.modalObject);
        this.classDiaryService.isModalStudentRegistersOpen$.next(false);
    }

    isSelected(item): boolean {
        if (!this.userEvents || !this.userEvents?.reasons?.length) {
            return false;
        }

        return this.userEvents.reasons.some((event) => event === item.id);
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    selectStudent(option: string): void {
        this.studentSelected = option;
        this.modalObject.frequency_user = this.studentSelected;
        this.userEvents = this.modalObject.frequency_user.events[this.registerType];

        this.setValuesForm(this.userEvents);
        this.isOpen = false;
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    }
}
