import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DisciplineSettingsVisibilityService } from 'src/app/pages/disciplines/components/settings/components/discipline-settings-visibility/discipline-settings-visibility.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-discipline-settings-visibility',
    templateUrl: './discipline-settings-visibility.component.html',
    styleUrls: ['./discipline-settings-visibility.component.scss']
})
export class DisciplineSettingsVisibilityComponent implements OnInit {
    external_id: any;
    settingsVisibility: any[];
    settingsVisibilityForm: UntypedFormGroup;
    public buttonActive = false;
    i18n: any = [];
    currentLanguage: any;
    isLoading = false;

    constructor(
        private disciplineSettingsVisibility: DisciplineSettingsVisibilityService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.getUrlParams();
        this.getVisibility(this.external_id);
        this.createVisibilityForm();
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUrlParams() {
        this.route?.parent?.parent?.params
            .subscribe((params) => {
                this.external_id = params.id;
            });
    }

    getVisibility(external_id: any) {
        this.disciplineSettingsVisibility.getDisciplineVisibility(external_id)
            .subscribe({
                next: (settingsVisibility) => {
                    this.setValueForm(settingsVisibility);
                    this.settingsVisibility = [settingsVisibility];
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.buttonActive = false;
                }
            });
    }

    postVisibilitySettings() {
        const visibility = this.settingsVisibilityForm.controls.visibility.value;
        const manualAccept = this.settingsVisibilityForm.controls.manual_accept.value;
        const params = {
            visibility,
            manual_accept: manualAccept,
        };
        this.disciplineSettingsVisibility.postDisciplineVisibility(this.external_id, params)
            .subscribe(() => {
                this.buttonActive = false;
            });
    }

    setValueForm(settingsVisibility: any) {
        this.settingsVisibilityForm.controls.visibility.setValue(settingsVisibility.visibility);
        this.settingsVisibilityForm.controls.manual_accept.setValue(settingsVisibility.manual_accept);
    }

    private createVisibilityForm() {
        this.settingsVisibilityForm = this.formBuilder.group({
            visibility: ['', [Validators.required]],
            manual_accept: ['false', [Validators.required]]
        });

        this.settingsVisibilityForm.valueChanges.subscribe(() => {
            this.buttonActive = true;
        });
    }
}
