import { formatDate } from '@angular/common';
import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { Calendar } from 'primeng/calendar';
import {
    filter, Subscription, take, tap
} from 'rxjs';

@Component({
    selector: 'app-picker-calendar',
    templateUrl: './picker-calendar.component.html',
    styleUrls: ['./picker-calendar.component.scss'],
})
export class PickerCalendarComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('calendarDate') selectorcalendarEndDate: Calendar;
    @ViewChild('calendarClick') calendarClickElement: ElementRef;

    @Input() Form: FormGroup;
    @Input() initialDate: Date = new Date();
    @Input() currentLanguage: string | null;
    @Input() editMode: boolean;
    @Input() inputControlNameMask: string;
    @Input() inputNameControlPicker: string;
    @Input() i18n: any;
    @Input() position = 'initial';
    @Input() minDateValidation: any;
    @Output() emitEvent = new EventEmitter();

    dateInputMask: any;
    enableCalendar = false;
    subscription: Subscription[] = [];

    ngOnInit(): void {
        this.createDateMaskInput();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.inputControlNameMask) {
                this.getValueChanges();
            }
        }
    }

    @HostListener('window:click', ['$event']) onClick(event: MouseEvent) {
        const targetElement = event.target as HTMLElement;
        if (this.calendarClickElement && !this.calendarClickElement.nativeElement.contains(event.target) && !(targetElement.classList.contains('pi') || targetElement.classList.contains('p-link') || targetElement.classList.contains('p-element'))) {
            this.enableCalendar = false;
        }
    }

    // startDate: [new Date()], -> No form deverá ter assim...
    get startDate() {
        return this.Form.get('startDate');
    }

    get inputValue() {
        return this.Form.get(this.inputControlNameMask);
    }

    getValueChanges() {
        if (this.inputValue) {
            this.inputValue
                .valueChanges
                .pipe(
                    filter((value) => value !== '' && this.isValidDate(value)),
                    take(1),
                    tap((value) => {
                        this.formatValueFromForm(value);
                    })
                )
                .subscribe();
        }
    }

    formatValueFromForm(newValue: string) {
        let date: Date;
        if (this.currentLanguage === 'en') {
            const [month, day, yearAndTime] = newValue.split('/');
            const [year, time] = yearAndTime.split(' ');
            date = new Date(`${year}-${month}-${day}T${time}`);
        } else {
            const [day, month, yearAndTime] = newValue.split('/');
            const [year, time] = yearAndTime.split(' ');
            date = new Date(`${year}-${month}-${day}T${time}`);
        }
        const format = this.currentLanguage === 'en' ? formatDate(date, 'MM/dd/yyyy hh:mma', 'en-US') : formatDate(date, 'dd/MM/yyyy HH:mm', 'pt-BR');
        this.Form.controls[this.inputControlNameMask]?.setValue(format, { emitEvent: false });
    }

    isValidDate(value: string): boolean {
        const date = new Date(value);
        return !Number.isNaN(date.getTime());
    }

    createDateMaskInput() {
        this.dateInputMask = createMask<Date>({
            alias: 'datetime',
            inputFormat: this.currentLanguage === 'en' ? 'mm/dd/yyyy hh:MMTT' : 'dd/mm/yyyy HH:MM',
            placeholder: '',
            oncleared: () => {
                this.selectorcalendarEndDate.clear();
                this.typeToPickerDate('');
            },
            oncomplete: () => {
                const dateValue = this.convertStringToDate(this.inputValue?.value, this.currentLanguage);
                this.typeToPickerDate(dateValue);
            }
        });
    }

    typeToPickerDate(value) {
        if (value) {
            if (value === '') {
                const format = this.currentLanguage === 'en' ? formatDate(value, 'MM/dd/yyyy hh:mma', 'en-US') : formatDate(value, 'dd/MM/yyyy HH:mm', 'pt-BR');
                this.Form.controls[this.inputControlNameMask].setValue(format);
                return;
            }

            if (!Number.isNaN(value.getTime())) {
                this.Form.controls[this.inputNameControlPicker].setValue(value);
                this.fnEmitValue(value, this.inputNameControlPicker);
            }
        }
    }

    // recebe evento quando usuário escolhe uma data vindo do Picker Calendar
    fnEmitValue(calendarDateValue?, formControlName?) {
        this.emitEvent.emit({ calendarDateValue, formControlName });
    }

    selectValueCalendar(calendarDateValue?, formControlName?) {
        // Coloca a data no input vindo do CalendarPicker
        if (calendarDateValue) {
            const format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy hh:mma', 'en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm', 'pt-BR');
            this.Form.controls[this.inputControlNameMask].setValue(format);
        }
        this.fnEmitValue(calendarDateValue, formControlName);
    }

    convertStringToDate(value: string, locale: any): Date {
        let dateValue: Date = new Date();
        if (value.length) {
            if (locale === 'en') {
                const [datePart, timePart] = value.split(' ');
                const [month, day, year] = datePart.split('/').map(Number);
                const [time, modifier] = [timePart.slice(0, 5), timePart.slice(-2).toUpperCase()];
                // eslint-disable-next-line prefer-const
                let [hours, minutes] = time.split(':').map(Number);

                if (modifier === 'PM' && hours !== 12) {
                    hours += 12;
                } else if (modifier === 'AM' && hours === 12) {
                    hours = 0;
                }
                dateValue = new Date(+year, +month - 1, +day, +hours, +minutes);
            } else {
                const [date, time] = value.split(' ');

                const [day, month, year] = date.split('/');
                const [hours, minutes] = time.split(':');

                dateValue = new Date(+year, +month - 1, +day, +hours, +minutes);
            }
        }
        return dateValue;
    }

    ngOnDestroy(): void {
        this.subscription.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
