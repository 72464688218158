import { formatDate } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GradesService } from 'src/app/pages/disciplines/components/grades/grades.service';
import { DisciplineSettingsGradesService } from 'src/app/pages/disciplines/components/settings/components/discipline-settings-grades/discipline-settings-grades.service';
import { SharedService } from 'src/app/shared/shared.service';
import { collapseAnimation, rotatedStateAnimation } from 'src/app/utils/animations/animations';

import { ContentSharedService } from '../../../../content-shared.service';
import { Level, SectionService } from '../../../../section.service';
import { EvaluativeResourceValue, SubstituteActivityService } from '../substitute-activity/substitute-activity.service';
import { parse } from 'path';

@Component({
    selector: 'app-evaluative-resource-options',
    templateUrl: './evaluative-resource-options.component.html',
    styleUrls: ['./evaluative-resource-options.component.scss'],
    animations: [
        collapseAnimation('250'),
        rotatedStateAnimation('250')
    ]
})
export class EvaluativeResourceOptionsComponent implements OnInit, OnDestroy {
    collapseOption = false;
    discipline_ext_id: any;
    periods: any;
    periodParams: any;
    categories: any = [];
    selectCategoriesPlaceholder: any;
    labelWeightName: any = '';
    available_grade: any = null;
    gradesConfigForm: UntypedFormGroup;
    gradesConfig: any;
    i18n: any = [];

    compositionTypes = [
        { id: 'best_grade', name: this.i18n.grades_forum_calculation_type_best_grade },
        { id: 'average_grade', name: this.i18n.grades_forum_calculation_type_average_grade },
    ];

    @Input() editMode: any;
    @Input() assignment: any;
    @Output() emitRubric = new EventEmitter();

    @Output() evaluationResources = new EventEmitter();
    @Output() weight = new EventEmitter();
    @Output() periodOutput = new EventEmitter();

    @ViewChild('categoryValue') categoryValue: ElementRef;
    @ViewChild('weightValue') weightValue: ElementRef;
    @ViewChild('compositionTypeValue') compositionTypeValue: ElementRef;

    params: any = {};

    period: any = null;
    category: any = null;
    sub: any;
    validationPointsRubrica = false;
    gradesSettings: any = {};

    subscriptions: Subscription[] = [];

    constructor(
        private gradesService: GradesService,
        private sectionService: SectionService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private contentSharedService: ContentSharedService,
        private disciplineSettingsGradesService: DisciplineSettingsGradesService,
        private sharedService: SharedService,
        private substituteActivityService: SubstituteActivityService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.createGradesConfigForm();
        this.getDisciplineId();
        this.getPeriods();
        this.selectCategoriesPlaceholder = 'Selecione um período';
        this.getGradesSettings();
        this.getEvaluativeResource();
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Grades'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
        };
        this.compositionTypes = [
            { id: 'best_grade', name: this.i18n.grades_forum_calculation_type_best_grade },
            { id: 'average_grade', name: this.i18n.grades_forum_calculation_type_average_grade },
        ];
    }

    getGradesSettings() {
        this.disciplineSettingsGradesService
            .getGradesSettings(this.discipline_ext_id)
            .subscribe((gradesSettings) => {
                this.gradesSettings = gradesSettings;
            });
    }

    createGradesConfigForm() {
        this.gradesConfigForm = this.formBuilder.group({
            period_id: ['', []],
            category_id: ['', []],
            composition_type: ['', []],
            weight: ['', [Validators.required, Validators.min(1)]],
        });
        this.gradesConfigForm.controls.category_id.valueChanges.subscribe(
            (changes: any) => {
                setTimeout(() => {
                    this.disableField();
                }, 50);
            }
        );

        this.gradesConfigForm.controls.period_id.valueChanges.subscribe(
            (changes: any) => {
                setTimeout(() => {
                    this.disableField();
                }, 50);
            }
        );
    }

    get category_id_value() {
        return this.gradesConfigForm.controls.category_id.value;
    }

    returnValuesPoints() {
        let maxLevelObj: Level = {} as Level;
        let sumPoints = 0;

        this.sectionService.criterions.forEach((e: any) => {
            if (e.length) {
                maxLevelObj = e.levels.reduce((prev, current) => (prev.points > current.points ? prev : current));
                sumPoints += parseFloat(maxLevelObj.points as unknown as string);
            }
        });

        return sumPoints;
    }

    setValueForm(grades: any) {
        let category: any = [];
        const periodIndex = this.periods?.findIndex((period: any) => (category = period?.categories.find((category: any) => grades?.grade_category_id == category.id)));
        if (periodIndex === -1) {
            return;
        }
        const gradesWeight = parseFloat(grades?.weight);
        const formatedGradesWeight = grades?.weight.replace('.', ',');

        this.collapseOption = true;

        this.getPeriodSelected(this.periods[periodIndex].id);
        this.checkCalculationType(this.periods[periodIndex].id, category?.id);

        // comentado pois acrescentava indevidamente somas nas notas
        // this.available_grade = parseFloat(category?.available_grade) + parseFloat(grades?.weight);

        // Validação de pontos usados na Rubrica maior que os pontos disponíveis
        if (this.sectionService.criterions?.length) {
            if (this.returnValuesPoints() > this.available_grade) this.validationPointsRubrica = this.validationPointsRubrica = true;
        }

        this.weight.emit(this.available_grade);

        this.gradesConfigForm.controls.period_id.setValue(
            this.periods[periodIndex].id
        );
        this.gradesConfigForm.controls.category_id.setValue(
            grades?.grade_category_id
        );
        this.gradesConfigForm.controls.composition_type.setValue(
            grades?.composition_type !== null ? grades?.composition_type : null
        );
        this.gradesConfigForm.controls.weight.setValue(formatedGradesWeight);

        this.periodOutput.emit({
            period: this.periods[periodIndex],
            grades: gradesWeight,
        });

        this.params = {
            grade_category_id: grades?.grade_category_id,
            weight: gradesWeight,
            composition_type: grades?.composition_type,
        };

        this.evaluationResources.emit(this.params);
    }

    toggle() {
        this.collapseOption = !this.collapseOption;
    }

    isButtonCreateRubricDisabled = false;
    disableField() {
        if (
            this.available_grade === undefined
            || this.available_grade == null
            || this.available_grade === 0
            || isNaN(this.available_grade)
        ) {
            this.gradesConfigForm.controls.weight.disable();
            this.isButtonCreateRubricDisabled = true;
        } else {
            this.isButtonCreateRubricDisabled = false;
            this.gradesConfigForm.controls.weight.enable();
        }
    }

    getPeriodSelected(period_id: any) {
        const period = this.periods.find((period: any) => period.id == period_id);

        if (period.categories.length === 0) {
            this.selectCategoriesPlaceholder = 'Nenhuma categoria encontrada';
        } else {
            this.selectCategoriesPlaceholder = 'Selecione uma categoria';
        }

        this.periodParams = period;
        this.categories = period.categories;
    }

    changePeriod() {
        if (
            this.weightValue?.nativeElement?.value !== undefined
            && this.categoryValue?.nativeElement?.value !== undefined
        ) {
            this.gradesConfigForm.controls.category_id.setValue(undefined);
            this.gradesConfigForm.controls.weight.setValue(undefined);
        }
        this.available_grade = '';
        this.params = {
            grade_category_id: null,
            weight: null,
        };
    }

    checkAvailableGrade(category): void {
        if (!category) return;

        if (this.editMode) {
            let grades = this.gradesConfig;

            if (grades) {
                if (typeof grades.weight === 'string') {
                    grades = parseFloat(grades);
                }

                if (grades.grade_category_id === this.category_id_value) {
                    this.available_grade = category.available_grade + grades.weight;
                } else {
                    this.available_grade = category.available_grade;
                }
            }
        } else {
            this.available_grade = category.available_grade;
        }
    }

    checkCalculationType(period_id: any, category_id: any) {
        const period = this.periods.find((period: any) => period.id == period_id);
        const category = period?.categories.find((category: any) => category.id == category_id);
        this.checkAvailableGrade(category);

        if (period?.calculation_type === 'sum') {
            this.labelWeightName = this.i18n?.grades_max_grades_task;
        } else {
            this.labelWeightName = this.i18n?.grades_weight;
        }
        if (this.weightValue?.nativeElement?.value !== undefined) {
            this.checkWeightInputValue();
        }
    }

    // Faz o alinhamento caso a nota digitada seja maior que a disponível no período
    checkWeightInputValue(): void {
        const inputWeightValue = this.gradesConfigForm.controls.weight.value;
        this.gradesConfigForm.controls.weight.setValue(inputWeightValue > this.available_grade ? this.available_grade : inputWeightValue);
    }

    getDisciplineId() {
        this.discipline_ext_id = this.router?.url.split('/')[2];
    }

    filterPeriodAvailable(periods) {
        const currentDateFormated = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        return periods.filter((period) => period.end_date > currentDateFormated);
    }

    getPeriods() {
        this.gradesService
            .getPeriodsList(this.discipline_ext_id)
            .subscribe((periods) => {
                this.periods = periods;
                localStorage.setItem('decimal_places_mask', periods[0]?.decimal_places);
                // Recebe Período e Categoria cadastrada se houver
                this.sub = this.contentSharedService.gradesConfig$.subscribe(
                    (grades: any) => {
                        if (Object.keys(grades).length != 0) {
                            this.gradesConfig = grades;
                            if (this.editMode) {
                                this.setValueForm(grades);
                            }
                        }
                    }
                );
            });
    }

    emitValue() {
        const weightFloat = parseFloat(this.weightValue.nativeElement.value.replace(',', '.'));

        this.params = {
            grade_category_id: parseInt(this.categoryValue.nativeElement.value),
            weight: weightFloat,
            composition_type: this.compositionTypeValue ? this.compositionTypeValue.nativeElement.value : null,
        };

        this.evaluationResources.emit(this.params);
        this.periodOutput.emit({
            period: this.periodParams,
            grades: weightFloat,
            composition_type: this.compositionTypeValue ? this.compositionTypeValue.nativeElement.value : null,
        });
    }

    goCreateRubrica(): void {
        this.emitRubric.emit();

        this.route.params.subscribe((params) => {
            const queryParams = {
                section: params.section,
                section_title: params.section_title,
                type: params?.type,
                calculation_type: this.periodParams.calculation_type,
                content: null,
                edit: null,
                weight: null
            };

            if (params?.edit === '1') {
                queryParams.content = params.content;
                queryParams.edit = params?.edit;
                queryParams.weight = this.gradesConfig?.weight || 0;
            } else {
                queryParams.weight = this.params.weight || 0;
            }

            this.router.navigate(['../create-rubrica', queryParams], {
                relativeTo: this.route,
            });
        });
    }

    // Remove do input letras e númeroa negativos
    validateWeight(event: Event): void {
        const decimalPlaces = parseInt(localStorage.getItem('decimal_places_mask') || '2', 10);
        const inputElement = event.target as HTMLInputElement;
        let weight = inputElement.value;

        // Remove caracteres que não são números ou vírgulas
        weight = weight.replace(/[^\d,]/g, '');

        // Substitui pontos por vírgulas
        weight = weight.replace(/\./g, ',');

        // Divide o número em duas partes: antes e depois da vírgula
        const parts = weight.split(',');

        // Se houver mais de uma vírgula, juntar as partes excedentes na primeira
        if (parts.length > 2) {
            weight = `${parts[0]},${parts.slice(1).join('')}`;
        }

        // Verifica se há casas decimais e limita à quantidade configurada
        if (parts.length === 2 && parts[1].length > decimalPlaces) {
            parts[1] = parts[1].slice(0, decimalPlaces); // Trunca casas decimais excedentes
            weight = `${parts[0]},${parts[1]}`;
        }

        // Substituir vírgula por ponto para tratar como decimal internamente
        const numericValue = parseFloat(weight.replace(',', '.'));

        let maxWeight = this.available_grade;

        if (this.editMode) {
            const grades = this.gradesConfig;

            if (grades.grade_category_id === this.category_id_value) {
                maxWeight = this.available_grade + parseFloat(this.gradesConfig?.weight || 0);
            }
        }

        // Verificar se o valor é maior que o máximo permitido (available_grade)
        if (!Number.isNaN(numericValue) && numericValue > maxWeight) {
            weight = maxWeight.toFixed(decimalPlaces).replace('.', ',');
        }

        // Atualiza o valor no campo imediatamente
        inputElement.value = weight;

        // Atualiza o controle do formulário
        this.gradesConfigForm.controls.weight.setValue(weight, { emitEvent: false });

        // Emitir o valor atualizado
        this.emitValue();
    }

    getEvaluativeResource(): void {
        // Seta no formulário os valores de acordo com a atividade substitutiva
        const subscription = this.substituteActivityService.evaluativeResourceValue$.subscribe({
            next: (response) => {
                if (Object.keys(response).length) {
                    const payloadTransformed = {
                        grade_category_id: response.category_id,
                        weight: response.weight,
                        composition_type: response.composition_type
                    };

                    this.setValueForm(payloadTransformed);
                }
            }
        });

        this.getEvaluativeResourceDisabledValue();
        this.subscriptions.push(subscription);
    }

    getEvaluativeResourceDisabledValue(): void {
        // Desabilita o formulário de acordo com a atividade substitutiva
        const subscription = this.substituteActivityService.evaluativeResourceDisabled$.subscribe({
            next: (response) => {
                if (response) {
                    this.gradesConfigForm.disable();

                    // Necessário por conta da função: disableField()
                    setTimeout(() => {
                        this.gradesConfigForm.controls.weight.disable();
                    }, 101);
                } else {
                    // Atualiza os pontos disponíveis
                    this.updateAvailableGrade();
                    this.gradesConfigForm.enable();
                }
            }
        });

        this.subscriptions.push(subscription);
    }

    updateAvailableGrade(): void {
        const periodValue = this.gradesConfigForm.controls.period_id.value;
        const categoryValue = this.gradesConfigForm.controls.category_id.value;

        if (periodValue && categoryValue) {
            this.checkCalculationType(periodValue, categoryValue);
            this.emitValue();
        }
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();

        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });

            this.substituteActivityService.evaluativeResourceValue$.next({} as EvaluativeResourceValue);
            this.substituteActivityService.evaluativeResourceDisabled$.next(false);
        }
    }

    getTraslatePoints(grade: number): string {
        let translatPointAvailable = this.i18n.grades_available_points;
        let gradeFormated = grade ? grade.toFixed(2) : '0';

        gradeFormated = gradeFormated.replace('.', ',');

        if (grade === 1) {
            translatPointAvailable = this.i18n.question_bank_available_point;
        }

        const msg = `${this.i18n.grades_you_have} ${gradeFormated} ${translatPointAvailable}`;

        return msg;
    }
}
