import {
    AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { delay, Subscription, take } from 'rxjs';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
import { LoginService } from 'src/app/pages/login/login.service';
import { ErrorReportingService } from 'src/app/services/errorReproting/error-reporting.service';
import { GoogleDrivePickerService } from 'src/app/services/google-drive-picker-service.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { DisplayPanelComponent } from '../display-panel/display-panel.component';
import { UploadService } from '../upload/upload.service';

@Component({
    selector: 'app-upload-inline',
    templateUrl: './upload-inline.component.html',
    styleUrls: ['./upload-inline.component.scss']
})
export class UploadInlineComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
    @ViewChild('btn_upload_google') btnUploadGoogle: ElementRef;
    @ViewChild(UploadInlineComponent) UploadInlineComponent: UploadInlineComponent;

    // inputs para configuração de visualização do componente upload
    @Input() maxFiles: number;// Número máximo de arquivos que o User pode fazer upload
    @Input() multiSelect = false;// Opção para User selecionar um arquivo por vez ou vários
    @Input() sharingStatus = false;// Opção para ser compartilhado de forma pública
    @Input() fileType: string;// Tipo do arquivo que será mostrado, se vazio busca todos
    @Input() files: any = [];// arquivos que vem do componente pai
    @Input() syncWithDrive = true;// arquivos que vem do componente pai
    @Input() assignment = '';// arquivos que vem do componente pai

    @Input() maxWidthDriveBtn = '';
    @Input() textExplanation = '';
    @Input() showRemoveBtn = true;
    @Input() showReplaceBtn = true;
    @Input() showOpenBtn = true;
    @Input() showDate = true;
    @Input() showSendTitle = true;
    @Input() textUploadBtn = '';
    @Input() isShowUploadTab = true;
    @Input() isShowExplanation = false;
    @Input() delivery_allowed = false;
    @Input() hasModalFileSharing = false;
    @Input() isModalFileSharingOpen = false;
    @Input() flowMethod = '';
    @Input() hasFileList = true;
    @Input() showFileList: boolean;

    @Input() hideMaxFileText = false;// Opção para esconder o texto de máximo de arquivos

    // outputs dos arquivos para componente pai
    @Output() filesEvent = new EventEmitter<any[]>();// Emissor dos arquivos que foram feito upload para componente pai
    @Output() closeEvent = new EventEmitter<any[]>();
    @Output() loadedFile = new EventEmitter<boolean>();
    @Output() isLoadingFile = new EventEmitter<boolean>();
    @Output() sentIsModalFileSharingOpen = new EventEmitter<boolean>();
    @Output() googlePostParams = new EventEmitter<any>();

    @Output() sendIsUploadLoading = new EventEmitter<boolean>();
    @Output() sendIsUploadLocked = new EventEmitter<boolean>();

    // consertar depois pois modais de erro estão ficando sobrepostos
    isVisible = true;

    // Variables
    googleData: any = [];
    user;
    token;
    provider: any;
    public picked: File;
    public status: string;
    uploadedFiles: any[] = [];
    dateFile = new Date();
    isLoaded = false;
    isPermissionDone: any;
    displayBasic = false;
    textError: string;
    isUploadLocked = false;
    select:string;
    date: Date;
    currentLanguage: any;
    isLoading = false;
    hasPermissionPicker = false;

    formData = new FormData();

    i18n: any;

    subscription: Subscription[] = [];

    constructor(
        public sharedService: SharedService,
        public googleDrivePickerService: GoogleDrivePickerService,
        public uploadService: UploadService,
        public platModalService: PlatformModalsService,
        public element: ElementRef,
        public route: ActivatedRoute,
        public contentSharedService: ContentSharedService,
        public localStorageService: LocalStorageService,
        public loginService: LoginService,
        public zone: NgZone,
        public cd : ChangeDetectorRef,
        public errorHandler: ErrorReportingService
    ) {}

    ngOnInit(): void {
        // Files commented below used for testing purposes
        // this.files = [
        // {
        //         id: 912, label: 'upload 2', external_id: '4WiObcSYkYMc6ntW', file_name: '', mime_type: 'application\/pdf', provider: 'GOOGLE_DRIVE', path: '1wXCBMBu3SjFNrN7D6A3YMIFIyjjMt6HgLoR-E-62mSg', video_uri: '', submitted_at: '2023-03-27 23:34:35', original: null
        //     },
        // {
        //         id: 913, label: 'arquivo-de-desenvolvimento-proletariado-contra-industrias.jpg', external_id: 'gGe5Txj7NSm9RAio', file_name: '', mime_type: 'application\/vnd.google-apps.spreadsheet', provider: 'GOOGLE_DRIVE', path: '1yT42ucKj9rsU-JXQSY8mrONiwIahvjCa', video_uri: '', submitted_at: '2023-03-27 11:34:37', original: null
        //     },
        // {
        //         id: 914, label: 'FilePNG.png', external_id: 'ZyMZHHIDUoZVvBmz', file_name: '', mime_type: 'application\/vnd.google-apps.video', provider: 'GOOGLE_DRIVE', path: '1RB7tN_Q_OJWlmSaEaz3tb_5qSG_isVFc', video_uri: '', submitted_at: '2023-03-27 11:34:39', original: null
        //     },
        // {
        //         id: 915, label: 'FilePNG.png', external_id: 'kDSJoM8xFbmBEvNi', file_name: '', uploadError: true, isUploading: false, messageError: `${'O arquivo'} ${'apresentou erro.'} ${'Por favor, envie-o novamente.'}`, mime_type: 'image\/png', provider: 'GOOGLE_DRIVE', path: '1yaVFh5Ab4PxhyAGPuriPGMOcr2y7QHpe', video_uri: '', submitted_at: '2023-03-27 11:34:41', original: null
        //     }
        // ];

        this.getTranslations();
        this.provider = localStorage.getItem('provider');
        this.getUser();
        this.getSyncWithDriveValue();
        this.localStorageService.checkCookiesEnabled();
        this.responseSubscriptionGoogleData();
    }

    ngAfterContentInit(): void {
        this.emitRemoveFiles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'files')) {
            this.files = this.files == null ? this.files = [] : this.files;
            if (this.files) {
                this.files.forEach((file:any) => {
                    const fileObj = file;
                    fileObj.submitted_at = Object.prototype.hasOwnProperty.call(file, 'created_at') ? file.created_at : file.submitted_at;
                });
                this.checkMaxFiles(this.files);
            }
        }
    }

    getSyncWithDriveValue() {
        const subscription = this.contentSharedService.syncWithDrive$.subscribe({
            next: (value) => {
                this.syncWithDrive = value;
            }
        });

        this.subscription.push(subscription);
    }

    interval;
    checkPickerTokenValidation() {
        let token: string | null = null;
        token = localStorage.getItem('googleAuthTokenPicker') || null;
        this.tokenInfoGoogle(token);

        this.interval = setInterval(() => {
            token = localStorage.getItem('googleAuthTokenPicker') || null;
            if (token !== null) {
                this.tokenInfoGoogle(token);
            }
        }, 300000);
    }

    tokenInfoGoogle(token) {
        this.loginService.tokenInfoGoogle(token).subscribe({
            next: (tokenInfo) => {
                if (!tokenInfo.scope.includes('auth/drive')) {
                    this.hasPermissionPicker = false;
                    return;
                }

                this.hasPermissionPicker = true;
            },
            error: () => {
                this.hasPermissionPicker = false;
            }
        });
    }

    defineTypeFile() {
        return 'audio/*,video/*,image/*,application/pdf';
    }

    closeModal() {
        this.closeEvent.emit(this.files);
        this.sharedService.modalClose();
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Upload'), ...this.sharedService.getTranslationsOf('Disciplines') };
        this.currentLanguage = localStorage.getItem('currentLanguage');

        if (this.provider === 'GOOGLE_DRIVE') {
            this.textUploadBtn = this.textUploadBtn === '' ? this.i18n.upload_from_google_drive : this.textUploadBtn;
        } else {
            this.textUploadBtn = this.textUploadBtn === '' ? this.i18n.upload_upload_it : this.textUploadBtn;
        }
    }

    // Functions
    getUser() {
        const data: any = localStorage.getItem('token');
        this.token = data;
        this.user = jwt_decode(data);
    }

    offset = 0;
    chunkSize = 1000000;
    countLoop: any = 1;

    countFiles = 0;
    totalFiles = 0;
    filesReceived: any = [];
    progressBar = 0;
    progressValue;
    progress;
    isUploadingFiles = false;
    isProcessingFile = false;

    calculateProgressBar(part, totalPart) {
        this.progressBar = Math.ceil((part * 100) / totalPart);
    }

    setValue(value) {
        this.progressValue.style.width = `${value}%`;
        this.progress.value = value;
    }

    fileSelected(event) {
        this.isUploadingFiles = true;
        this.filesReceived = Array.from(event.target.files);
        this.totalFiles = this.filesReceived.length;
        if (this.totalFiles > this.maxFiles) {
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
            return;
        }
        this.fileSetup(this.filesReceived[this.countFiles]);
    }

    fileSetup(file) {
        // Tamanho do arquivo maior que 8MB
        if (file.size > 8000000) {
            this.chunkSize = 8000000;
        }
        if (this.countFiles < this.totalFiles) {
            this.sendChunkPart(this.offset, file);
            this.isLoadingFile.emit(true);
        }
    }

    sendChunkPart(offset, file) {
        const totalPart: any = Math.ceil(file.size / this.chunkSize) === 0 ? 1 : Math.ceil(file.size / this.chunkSize);

        if (offset < file.size) {
            if (offset + this.chunkSize >= file.size) {
                this.chunkSize = file.size - offset;
                this.isProcessingFile = true;
            }

            const chunk = file.slice(offset, offset + this.chunkSize);
            this.formData = new FormData();
            this.formData.append('file[]', chunk, file.name);
            this.formData.append('mime', file.type);
            this.formData.append('part', this.countLoop.toString());
            this.formData.append('totalPart', totalPart.toString());
            this.postFiles(this.formData, file);
            this.calculateProgressBar(this.countLoop, totalPart);
        }
    }

    postFiles(files: any, file) {
        this.uploadService.postFile(files).subscribe({
            next: (response:any) => {
                this.offset += this.chunkSize;

                this.countLoop += 1;

                if (this.offset < file.size) {
                    this.sendChunkPart(this.offset, file);
                } else {
                    response.forEach((item:any) => {
                        this.files.push(item);
                    });
                    this.countFiles += 1;

                    if (this.countFiles < this.totalFiles) {
                        this.formData = new FormData();
                        this.offset = 0;
                        this.countLoop = 1;
                        this.isProcessingFile = false;
                        this.fileSetup(this.filesReceived[this.countFiles]);
                    } else {
                        console.log('this.totalFiles ', this.totalFiles);
                        console.log('this.countFiles ', this.countFiles);

                        if (this.totalFiles === this.countFiles) {
                            this.isLoadingFile.emit(false);
                        }

                        this.resetPostFileVariables();
                        this.checkMaxFiles(this.files);
                        this.isUploadingFiles = false;
                        this.isProcessingFile = false;
                        this.filesEvent.emit(this.files);
                    }
                }
            },
            error: (err) => {
                if (err.status === 500 || err.status === 413) {
                    setTimeout(() => {
                        this.postFiles(files, file);
                    }, 10000);
                }
            }
        });
    }

    resetPostFileVariables() {
        this.formData = new FormData();
        this.offset = 0;
        this.countLoop = 1;
        this.countFiles = 0;
        this.totalFiles = 0;
        this.isProcessingFile = false;
    }

    replaceFileGCS(fileGCS) {
        this.isUploadingFiles = true;
        const fileToReplace = this.files.indexOf(fileGCS.file);

        if (fileToReplace > -1) {
            this.files.splice(fileToReplace, 1);
            this.fileSelected(fileGCS.event);
        }
    }

    showCookieFail() {
        this.isVisible = false;
        this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
    }

    setFileSelection(): string {
        return this.multiSelect ? 'multiple' : 'single';
    }

    isPickingFiles = false;
    // Requests Provider GOOGLE_DRIVE when has permission to Drive
    openGoogleDrivePicker() {
        this.sharedService.checkIfApiKeyExists();
        if (!localStorage.getItem('apiKey')) {
            return;
        }
        this.isLoading = true;
        this.isPickingFiles = true;
        this.loadedFile.emit(false);
        this.sendIsUploadLoading.emit(this.isLoading);

        this.googleDrivePickerService.open(this.isShowUploadTab, this.setFileSelection(), this.fileType, this.reponseGoogleData);
    }

    reponseGoogleData = (data) => {
        console.log('data ', data);

        this.isPickingFiles = false;

        if (data.action === 'picked') {
            console.log('data ', data);

            this.googleData = data.docs;

            if (this.hasModalFileSharing) {
                this.sentIsModalFileSharingOpen.emit(true);
                this.googlePostParams.emit(this.googleData);
                this.googleData = [];
                this.googleDrivePickerService.isPickerAlreadyCreated = false;
                return;
            }

            if (this.googleData.length > this.maxFiles) {
                this.googleData = [];
                this.googleDrivePickerService.isPickerAlreadyCreated = false;
                this.isLoading = false;
                this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
                return;
            }

            this.callInsertPermission(this.googleData);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }

        if (data.action === 'cancel') {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
            if (this.files.length) {
                this.loadedFile.emit(true);
            } else {
                this.loadedFile.emit(false);
            }
        }
    };

    rebuildFile(file) {
        return {
            ...file,
            isUploading: true,
            uploadError: false,
            mime_type: file.mimeType,
            label: file.name
        };
    }

    responseSubscriptionGoogleData() {
        const subscription = this.googleDrivePickerService.callbackReponse$.subscribe({
            next: (response) => {
                console.log('response ', response.message);

                if (!this.sharedService.isCookiesEnabled()) {
                    this.showCookieFail();
                }

                if (Object.prototype.hasOwnProperty.call(response, 'error') || Object.prototype.hasOwnProperty.call(response, 'message')) {
                    if (response.details === 'Cookies are not enabled in current environment.') {
                        this.isVisible = false;
                        this.platModalService.getModalState('message').pipe(take(1)).subscribe({
                            next: (state) => {
                                if (!state) {
                                    this.platModalService.toggle('message', this.i18n.upload_content_help_cookies_blocked, 'close');
                                }
                            }
                        });
                    }

                    if (response.message === 'Popup window closed' || response.error === 'access_denied') {
                        this.isLoading = false;
                        this.sendIsUploadLoading.emit(this.isLoading);
                        this.googleData = [];
                        this.googleDrivePickerService.isPickerAlreadyCreated = false;
                        this.platModalService.getModalState('message').pipe(take(1)).subscribe({
                            next: (state) => {
                                if (!state) {
                                    this.platModalService.toggle('message', this.i18n.upload_closes_pop_consent, 'close');
                                }
                            }
                        });
                    }

                    if (Object.prototype.hasOwnProperty.call(response.error, 'errCode') && response.error.errCode === 400) {
                        if (response.error.error === 'invalid_grant') {
                            // this.sharedService.modalClose();
                            this.isVisible = false;

                            this.platModalService.toggle('message', this.i18n.upload_content_google_invalid_grant, {
                                finally: () => {
                                    this.platModalService.close('message');
                                    this.sharedService.modalClose();
                                    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
                                    const redrectTo = this.route.snapshot['_routerState'].url;

                                    this.sharedService.logOut(redrectTo);
                                    this.googleDrivePickerService.callbackReponse$.next({});
                                }
                            });
                        }
                    }
                }
            }
        });

        this.subscription.push(subscription);
    }

    countFilesInsertedPermission = 0;
    countFilesSize = 0;
    countFilesToUpload = 0;
    countFilesUploaded: any = [];
    fileMessageError = '';

    async callInsertPermission(files: any) {
        if (Object.prototype.hasOwnProperty.call(files, 'flow_method')) {
            this.flowMethod = files.flow_method;
        }
        const el: any = document.querySelector('app-content-task-upload');
        if (el !== null) {
            el.click();
        }
        this.countFilesInsertedPermission = 0;
        this.isUploadingGoogleFiles = true;
        this.isLoadingFile.emit(this.isUploadingGoogleFiles);
        this.countFilesSize = 0;
        this.hasError = false;
        this.countFilesSize = files.length;
        this.countFilesToUpload = files.slice(0, this.maxFiles - this.files.length).length;
        const countLeftingFilesFromPicker = files.length - this.countFilesToUpload;
        let batchSize = 4;

        const processBatch = async (batch) => {
            try {
                const processedFiles = await Promise.all(batch.map((file) => this.processFile(files, file)));
                processedFiles.forEach((file) => {
                    this.files.push(this.rebuildFile(file));
                    this.countFilesInsertedPermission += 1;
                    this.calculateProgressBar(this.countFilesInsertedPermission, this.countFilesSize);
                });
                await this.uploadBatch(processedFiles);
            } catch (error: any) {
                this.hasError = true;
                this.errorHandler.handleError(error);
                throw new Error(error.toString());
            }
        };

        const processFiles = async () => {
            let index = 0;
            while (index < files.length && !this.hasError && this.files.length < this.maxFiles) {
                if (batchSize + this.files.length > this.maxFiles) {
                    batchSize = this.maxFiles - this.files.length;
                    this.hasError = true;
                }

                const batch = files.slice(index, index + batchSize);
                // eslint-disable-next-line no-await-in-loop
                await processBatch(batch);
                index += batchSize;
            }
        };

        try {
            await processFiles();
        } catch (error) {
            this.googleData = [];
            this.countFilesInsertedPermission = 0;
            this.isLoading = false;
            this.isUploadingGoogleFiles = false;
            this.isLoadingFile.emit(this.isUploadingGoogleFiles);
            this.hasError = true;
            this.isProcessingFile = false;
        }

        if (countLeftingFilesFromPicker + this.files.length > this.maxFiles) {
            this.sharedService.modalClose();
            this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
        }

        this.countFilesInsertedPermission = 0;
        this.progressBar = 0;
        this.isUploadingGoogleFiles = false;
        this.isLoadingFile.emit(this.isUploadingGoogleFiles);
        this.sendIsUploadLoading.emit(this.isUploadingGoogleFiles);
    }

    processFile = (files, file) => new Promise((resolve, reject) => {
        this.googleDrivePickerService.insertPermission(file.id, (response) => {
            if (response.status === 200) {
                resolve(file);
            } else {
                let error = '';
                switch (response.status) {
                    case 401:
                        error = 'upload_error_unauthorized';
                        break;
                    case 403:
                        error = 'upload_error_permission';
                        break;
                    case 404:
                        error = 'upload_error_file_not_found';
                        break;
                    default:
                        error = 'upload_error';
                        break;
                }

                // Open modal to warn user
                if (this.i18n && this.i18n[error]) {
                    const fileName = files[this.countFilesInsertedPermission].name;
                    const messageError = `${this.i18n.upload_the_file} ${fileName} ${this.i18n.upload_got_an_error} ${this.i18n[error]}  <br>${this.i18n.upload_check_if_shortcut_file} <br>${this.i18n.upload_please_send_again_singular}`;
                    this.isLoading = false;
                    this.sendIsUploadLoading.emit(this.isLoading);

                    this.platModalService
                        .getModalState('message')
                        .pipe(delay(0), take(1))
                        .subscribe((openedModal) => {
                            if (openedModal) {
                                this.platModalService.close('message');
                            }
                            this.platModalService.toggle('message', messageError, 'close');
                        });
                }

                // Send errors to Error reporting
                let messageErrors = '';
                messageErrors += `user: ${JSON.stringify(this.token)} `;
                if (Object.prototype.hasOwnProperty.call(response, 'result')) {
                    if (Object.prototype.hasOwnProperty.call(response, 'error')) {
                        if (Object.prototype.hasOwnProperty.call(response, 'errors')) {
                            if (response.result.error.errors.length) {
                                response.result.error.errors.forEach((errorItem) => {
                                    const errorObj = errorItem;
                                    errorObj.file_id = files[this.countFilesInsertedPermission].id;
                                    messageErrors += `${JSON.stringify(errorObj)} `;
                                });
                            }
                        }
                        messageErrors += JSON.stringify(response.result.error);
                    }
                    messageErrors += JSON.stringify(response.result);
                }
                reject(new Error(messageErrors));
            }
        });
    });

    isUploadingGoogleFiles = false;
    hasError = false;
    async uploadBatch(files: any) {
        return new Promise<void>((resolve, reject) => {
            const googleFiles: any = [];
            for (let i = 0; i < files.length; i += 1) {
                const element = files[i];
                googleFiles.push(element.id);
            }
            let params: any = {};

            if (this.sharingStatus) {
                params = {
                    public: true,
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            } else {
                params = {
                    provider: 'GOOGLE_DRIVE',
                    file: googleFiles,
                };
            }

            if (!this.syncWithDrive) {
                params.syncWithDrive = this.syncWithDrive;
            }

            if (this.flowMethod === '') {
                this.flowMethod = 'platform';
            }
            params.flow_method = this.flowMethod;

            this.uploadService.uploadFile(params).subscribe({
                next: (response:any) => {
                    this.zone.run(() => {
                        response.forEach((item:any) => {
                            this.countFilesUploaded.push(item);
                            const file = this.files.find((fileSearch) => fileSearch.id === item.path);

                            if (file) {
                                file.uploadError = false;
                                file.isUploading = false;
                                file.submitted_at = Object.prototype.hasOwnProperty.call(file, 'created_at') ? file.created_at : file.submitted_at;
                                Object.assign(file, item);
                            }
                        });
                        this.checkMaxFiles(this.files);

                        this.filesEvent.emit(this.files);

                        if (this.countFilesToUpload === this.countFilesUploaded.length) {
                            this.loadedFile.emit(true);

                            this.isUploadingGoogleFiles = false;
                            this.isLoadingFile.emit(this.isUploadingGoogleFiles);
                            this.countFilesUploaded = [];
                            this.isLoading = false;
                        }
                        this.googleData = [];
                        resolve();
                    });
                },
                error: (err) => {
                    this.zone.run(() => {
                        this.googleData = [];
                        this.countFilesInsertedPermission = 0;
                        this.isLoading = false;
                        this.isUploadingGoogleFiles = false;
                        this.isLoadingFile.emit(this.isUploadingGoogleFiles);
                        this.hasError = true;
                        this.isProcessingFile = false;

                        this.platModalService
                            .getModalState('message')
                            .pipe(take(1))
                            .subscribe((openedModal) => {
                                if (openedModal) {
                                    this.platModalService.close('message');
                                }
                            });

                        if (err.status === 403) {
                            this.platModalService.toggle('message', this.i18n.upload_no_permission, 'close');
                            reject(new Error(JSON.stringify(err)));
                        }

                        const filesNotUploaded = () => this.files.filter((file) => !file.external_id);
                        const showFileNames = () => filesNotUploaded().map((findFile, index) => `<br><b>${index + 1} - ${findFile.name}</b>`).join('');
                        const messageError = `${this.i18n.upload_the_files} ${showFileNames()} ${this.i18n.upload_got_errors} ${this.i18n.upload_please_send_again_plural}`;

                        const updateFilesStatus = () => filesNotUploaded().forEach((findFile) => {
                            const findFileObj = findFile;
                            findFileObj.isUploading = false;
                            findFileObj.uploadError = true;
                            findFileObj.messageError = `${this.i18n.upload_the_file} ${this.i18n.upload_got_error} ${this.i18n.upload_please_send_again_singular}`;
                        });

                        updateFilesStatus();
                        this.loadedFile.emit(true);

                        this.platModalService.toggle('message', messageError, 'close');
                        reject(new Error(JSON.stringify(err)));
                    });
                }
            });
        });
    }

    replaceGoogleData = (data) => {
        const fileToReplace = this.files.findIndex((file) => file.external_id === this.fileToReplace.external_id);

        if (fileToReplace === -1) {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.platModalService.toggle('message', this.i18n.upload_error_replace_file, 'close');
            return;
        }

        if (fileToReplace > -1 && data.action === 'picked') {
            this.files.splice(fileToReplace, 1);
            this.googleData = data.docs;
            this.callInsertPermission(this.googleData);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }

        if (data.action === 'cancel') {
            this.isLoading = false;
            this.sendIsUploadLoading.emit(this.isLoading);
            this.googleData = [];
            this.googleDrivePickerService.isPickerAlreadyCreated = false;
        }
    };

    fileToReplace;
    replaceFile(file: any) {
        this.fileToReplace = file;
        this.isLoading = true;
        this.sendIsUploadLoading.emit(this.isLoading);
        this.googleDrivePickerService.open(this.isShowUploadTab, 'single', this.fileType, this.replaceGoogleData);
    }

    checkMaxFiles(files: any) {
        if (files !== null) {
            if (files.length >= this.maxFiles) {
                this.isUploadLocked = true;
            } else {
                this.isUploadLocked = false;
            }

            this.sendIsUploadLocked.emit(this.isUploadLocked);

            if (files.length > 0) {
                this.isLoaded = true;
                // this.sharedService.isLoadedUploadTrue();
            } else {
                this.isLoaded = false;
                // this.sharedService.isLoadedUploadfalse();
            }
        }
    }

    decisionTypePlatModalService: any = null;
    removeFile(file:any) {
        this.platModalService.toggle('decision', 'deletion_subject_generic', {
            forward: () => {
                if (this.files.length >= 1) {
                    const fileToRemove = this.files.indexOf(file);

                    if (fileToRemove > -1) {
                        this.files.splice(fileToRemove, 1);
                        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: true });
                    }

                    this.checkMaxFiles(this.files);
                }
            },
            finally: () => {
                this.platModalService.close('decision');
            },
        });
    }

    emitRemoveFiles() {
        const subscription = this.sharedService.removeFiles$.subscribe((item: any) => {
            if (item?.isUpdated) {
                this.filesEvent.emit(item?.files);
            }
        });

        this.subscription.push(subscription);
    }

    @ViewChild('displayPanelWarning') displayPanelWarning! : DisplayPanelComponent;
    displayPanelObject: any = {};
    getLocationToDisplayPanel($event, message: any) {
        const windowWidth = window.innerWidth;

        const top = $event.clientY + 10;
        const left = $event.clientX + 8;
        this.displayPanelObject.top = `${top}px`;
        this.displayPanelObject.left = `${left}px`;
        if (windowWidth < 1065) {
            this.displayPanelObject.left = '';
            this.displayPanelObject.right = '0px';
        }

        if (message) {
            this.textFlowMethodWarning = message;
            this.displayPanelWarning.toggle($event);
        }
    }

    textFlowMethodWarning = '';
    returnMessageFlowMethod(file: any) {
        // if file removed return file removed message
        if (file.trashed) {
            return this.i18n.disciplines_file_sharing_warning_file_removed;
        }
        return file.flow_method === 'platform'
            ? this.i18n.disciplines_file_sharing_warning_flow_method_platform
            : this.i18n.disciplines_file_sharing_warning_flow_method_external_drive;
    }

    ngOnDestroy() {
        this.files = [];
        this.filesEvent.emit(this.files);
        this.closeEvent.emit(this.files);
        this.sharedService.removeFiles$.next({ files: this.files, isUpdated: false });

        if (this.subscription.length) {
            this.subscription.forEach((element) => {
                element.unsubscribe();
            });
        }

        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
