<div class="w-full">
    <div class="input-container">
        <div
            class="flex items-center gap-2 border border-dashed border-customGray-default rounded-sm p-2 leading-5"
            (click)="toggleCalendar()"
        >
            <button>
                <span
                    class="text-customGray-default iconify w-5 h-5 cursor-pointer"
                    data-icon="pixelarticons:calendar-text"
                ></span>
            </button>

            <div class="text-customGray-grayThree text-14">
                {{ getDayOfWeek(inputDate) }}
            </div>

            <input
                [inputMask]="dateInputMask"
                type="text"
                class="text-customGray-grayThree text-14"
                [(ngModel)]="inputDate"
            />
        </div>

        <div
            [ngClass]="{ visbile: enableCalendar, hidden: !enableCalendar }"
            class="relative calendar-container cursor-pointer container_calendar_component"
        >
            <div class="w-full" [style.position]="position">
                <div class="relative">
                    <div
                        (click)="enableCalendar = false"
                        style="
                            position: absolute;
                            right: 5px;
                            z-index: 100;
                            height: 0;
                            top: 8px;
                        "
                    >
                        <img
                            src="../../../assets/svg/certificate/close.svg"
                            alt="close"
                        />
                    </div>
                    <p-calendar
                        #calendarDate
                        [dateFormat]="
                            currentLanguage === 'en'
                                ? 'MM/dd/yyyy'
                                : 'dd/MM/yyyy'
                        "
                        [showTime]="false"
                        [inline]="true"
                        [minDate]="minDateValidation"
                        [maxDate]="maxDateValidation"
                        [readonlyInput]="false"
                        [styleClass]="'calendarStyle'"
                        [placeholder]="'01/01/2023 20:01'"
                        (onSelect)="fnPickDateToInput(calendarDate.value)"
                        [(ngModel)]="date"
                        (onMonthChange)="changeMonth($event)"
                    >
                        <ng-template pTemplate="date" let-date>
                            <div
                                [ngClass]="{
                                    'background_blue': hasStatusFinished(
                                        date.day
                                    )
                                }"
                            >
                                <span>{{ date.day }}</span>
                            </div>
                        </ng-template>
                    </p-calendar>
                </div>
            </div>
        </div>
    </div>
</div>
