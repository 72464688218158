import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-modal-profile-impersonate',
  templateUrl: './modal-profile-impersonate.component.html',
  styleUrls: ['./modal-profile-impersonate.component.scss']
})
export class ModalProfileImpersonateComponent implements OnChanges {
  @Input() i18n: any = [];
  @Input() impersonateUser: any = {};
  @Input() isVisible: boolean = false;
  @Output() sendCloseModal = new EventEmitter();
  @Output() sendProfileToImpersonate = new EventEmitter();

  selected: any = '';
  profile: any = []

  constructor() { }

  ngOnChanges(): void {
    if (Object.keys(this.impersonateUser).length) {
      if (this.impersonateUser.roles.length) {
        this.impersonateUser.roles = this.filterRolesByExternalId(this.impersonateUser.roles);
      }
    }
  }
  
  filterRolesByExternalId(roles: any[]) {
    const filteredRoles = Object.values(
      roles.reduce((acc, curr) => {
        if (!acc[curr.role_external_id]) {
          acc[curr.role_external_id] = curr;
        }
        return acc;
      }, {})
    );
    return filteredRoles;
  }

  closeModal(){
    this.selected = '';
    this.sendCloseModal.emit(false);
  }

  selectProfileToImpersonate(profile: any){
    this.profile = profile;
    this.selected = profile?.role_external_id;
  }

  emitProfileToImpersonate(){
    const params = {
      "user_external_id": this.impersonateUser.external_id,
      "role_external_id": this.selected
    }
    this.selected = '';
    this.sendProfileToImpersonate.emit(params)
  }

}
