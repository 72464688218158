<section>
  <div class="flex justify-between flex-wrap">
    <h1 class="text-left text-customBlue-default font-bold text-22 mb-5">
      {{ i18n.grades_grades }}
    </h1>
    <div *ngIf="lastPeriodAvarage.status === 'closed'"
    class="text-center text-customBlue-default bg-customWhite-default px-10 py-1.5 rounded-2xl">
      <p class="font-semibold">{{ i18n.grades_final_grade }}: {{ lastPeriodAvarage.name }}</p>
      <p class="font-bold text-40">{{ lastPeriodAvarage.average }}</p>
    </div>
  </div>
  <ul>
    <li class="my-4" *ngFor="let period of periods">
      <app-custom-dropdown
        [title]="period?.name"
        [stylization]="dropdownDefaultStyle"
      >
        <div class="bg-customWhite-default rounded-md w-auto overflow-auto">
          <table class="bg-customWhite-default rounded-md w-full rounded-t-lg">
            <thead>
              <tr class="text-customBlue-default text-18 font-bold">
                <th class="text-left p-4 pl-8 w-80">
                  {{ i18n.grades_category }}
                </th>
                <th class="sm:text-center px-4">{{ i18n.grades_lesson }}</th>
                <th class="text-center" [style.width]="'14.375rem'">
                  {{ i18n.grades_grade }}
                </th>
                <th><!-- Required --></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let grade of period?.student?.grades">
                <td>
                  <p class="truncate p-2 pl-8 text-customGray-dark">
                    {{ grade.category_name }}
                  </p>
                </td>
                <td>
                  <p
                    class="truncate sm:text-center text-customGray-dark p-2 px-4"
                  >
                    {{ grade.content_title }}
                  </p>
                </td>
                <td>
                  <span
                    class="block text-center text-customGray-dark p-2 px-4"
                    [ngClass]="{
                      'bg-no-evaluation rounded-sm': grade?.evaluation == null
                    }"
                  >
                    <div>
                        {{ formatAverage(grade?.evaluation) }}
                    </div>
                    <div *ngIf="grade?.evaluation == null">
                      {{ i18n.grades_no_grade }}
                    </div>
                  </span>
                </td>
                <td class="w-px">
                    <!-- Falta implementação no Back-end  -->
                    <!-- <button type="button" class="p-2">
                        <span class="iconify text-customGray-default text-19" data-icon="majesticons:chat-2-line"></span>
                    </button> -->
                </td>
              </tr>
              <!-- Falta implementação no Back-end  -->
              <!-- <tr>
                <td colspan="4">
                    <div class="p-4 text-customGray-dark border-customGray-lighterGray border-t">
                        <p class="font-semibold">Comentários nota</p>
                        <div class="p-4">
                            <div class="flex items-center justify-between">
                                <div class="flex items-center gap-2">
                                    <app-user-frame [img_src]="''" [user_name]="'Ivete Sangalo'"></app-user-frame>
                                    <p class="font-bold">Ivete Sangalo</p>
                                    <span class="text-14 block px-2 italic rounded text-customWhite-default bg-customBlue-royal">Professora</span>
                                </div>
                                <p class="italic">Publicado há 3 dias</p>
                            </div>
                            <p class="mt-1">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when anprinter unknown printer took a galley of type and scrambled...</p>
                        </div>
                    </div>
                </td>
              </tr> -->
              <tr class="border-t border-customGray-lighterGray">
                <td
                  class="text-left text-customBlue-default text-18 font-bold py-4 pr-4 pl-8"
                >
                  {{ i18n.grades_final_grade }}
                </td>
                <td></td>
                <td class="text-center p-4">
                  <div class="text-customBlue-default text-30 font-bold">
                    <div>
                        {{ formatAverage(period?.student?.average) }}
                    </div>
                    <div
                      *ngIf="period?.student?.average == null"
                      class="text-18"
                    >
                      {{ i18n.grades_no_grade }}
                </div>
                  </div>
                  <div class="flex justify-center items-center">
                    <ng-container [ngSwitch]="period?.student?.status">
                      <div
                        *ngSwitchCase="'grade_status_approved'"
                        class="flex items-center gap-1 text-customGreen-approved"
                      >
                        <span
                          class="iconify w-4 h-4"
                          data-icon="akar-icons:circle-check"
                        ></span>
                        <p class="text-12 whitespace-nowrap">
                          {{ i18n.grades_approved }}
                        </p>
                      </div>
                      <div
                        *ngSwitchCase="'grade_status_reproved'"
                        class="flex items-center gap-1 text-inicieColors-orange"
                      >
                        <span
                          class="iconify w-5 h-5"
                          data-icon="mingcute:close-circle-line"
                        ></span>
                        <p class="text-12 whitespace-nowrap">
                          {{ i18n.grades_reproved }}
                        </p>
                      </div>
                      <div
                        *ngSwitchCase="'grade_status_open'"
                        class="flex items-center gap-1 text-customBlue-royal"
                      >
                        <span
                          class="iconify w-5 h-5"
                          data-icon="ph:dots-three-circle"
                        ></span>
                        <p class="text-12 whitespace-nowrap">
                          {{ i18n.grades_open }}
                        </p>
                      </div>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-custom-dropdown>
    </li>
  </ul>
  <app-empty-data class="block p-4" *ngIf="!periods.length"></app-empty-data>
</section>
