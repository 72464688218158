import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CertificatesValidatorComponent } from './certificates-validator.component';
import { ValidateComponent } from './components/validate/validate.component';
import { ValidatorComponent } from './components/validator/validator.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'validator',
        pathMatch: 'full'
    },
    {
        path: '',
        component: CertificatesValidatorComponent,
        children: [
            {
                path: ':id/validate', component: ValidateComponent
            },
            {
                path: 'validator', component: ValidatorComponent
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CertificatesValidatorRoutingModule { }
