import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCustomPercentAverage]',
})
export class CustomPercentAverageDirective implements OnInit {
  @Output() onCurrencyEvent = new EventEmitter();
  @Input() maxGrade: number = 100; // Valor máximo dinâmico
  @Input() calculationType: 'sum' | 'weighted_average' = 'weighted_average';

  private isUpdating: boolean = false; // Variável para evitar loops infinitos

  constructor(public ngControl: NgControl) {}

  ngOnInit() {}

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement) {
    if (this.isUpdating) return; // Evita recursão
    this.isUpdating = true;

    let value = target.value;

    // Remove o símbolo de porcentagem apenas para manipulação interna
    if (value.endsWith('%')) {
      value = value.slice(0, -1);
    }

    // Remove caracteres inválidos
    let newVal = value.toString().replace(/[^\d,]/g, '').replace('.', ',');

    // Permite apagar o valor completamente
    if (!newVal) {
      this.ngControl.control?.setValue('', { emitEvent: false });
      this.ngControl.valueAccessor?.writeValue(''); // Limpa o input
      this.isUpdating = false;
      this.onCurrencyEvent.emit('');
      return;
    }

    // Impede mais de uma vírgula
    const commaCount = (newVal.match(/,/g) || []).length;
    if (commaCount > 1) {
      newVal = newVal.replace(/,/g, (match, offset, str) => {
        return str.indexOf(',') === offset ? ',' : '';
      });
    }

    // Limita ao valor máximo permitido
    const maxLimit = this.calculationType === 'sum' ? this.maxGrade || 10 : 100;
    if (parseFloat(newVal.replace(',', '.')) > maxLimit) {
      newVal = maxLimit.toString().replace('.', ','); // Ajusta o valor ao máximo permitido
    }

    // Adiciona o símbolo de porcentagem apenas para exibição
    if (this.calculationType === 'weighted_average' && newVal) {
      newVal += '%';
    }

    // Atualiza o valor do controle no formulário
    this.ngControl.control?.setValue(newVal.replace('%', ''), { emitEvent: false });
    this.ngControl.valueAccessor?.writeValue(newVal); // Atualiza a exibição no input

    // Garante que o cursor permaneça na posição correta
    if (this.calculationType === 'weighted_average' && newVal.endsWith('%')) {
      const cursorPos = target.selectionStart || 0;
      const newCursorPos = Math.min(cursorPos, newVal.length - 1); // Antes do '%'
      setTimeout(() => target.setSelectionRange(newCursorPos, newCursorPos), 0);
    }

    this.isUpdating = false; // Reseta o controle para permitir novas alterações
    this.onCurrencyEvent.emit(newVal); // Emite o valor corrigido
  }
}
