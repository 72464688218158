import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements AfterViewInit {
    defaultStylization = {
        borderWidth: 1,
        closed: {
            borderColor: '#E3E4E4',
            textColor: '#233674',
            iconColor: '#233674',
            fontWeight: 'none',
            backgroundColor: '',
        },
        expanded: {
            borderColor: '#E3E4E4',
            textColor: '#233674',
            iconColor: '',
            fontWeight: 'none',
            backgroundColor: '',
        },
    };

    @Input() title = 'your_dropdown_name_here';
    @Input() block = true;
    @Input() stylization = this.defaultStylization;
    @Input() marked: boolean | string = false;
    @Input() awaysOpen = false;
    @Input() autoClose = false;
    @Input() initOpen = false;
    @Input() isTextCenter = false;
    @Input() dropdownStyle = '';

    @ViewChild('dropdown') dropdown: ElementRef;
    dropdownExpanded = false;
    dropdownKey: symbol;
    dropdownHeight: string;

    // dropdownStyle: string;
    dataId: string;
    constructor(
        public modals: PlatformModalsService,
        private shared: SharedService,
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.cd.detectChanges();
        switch (true) {
            case this.awaysOpen:
                this.dropdownHeight = '--height: \'auto\''; /* setting the dropdown height when active */
                break;
            case this.initOpen:
                this.dropdownHeight = '--height: \'auto\''; /* setting the dropdown height when active */
                this.dropdownExpanded = true;
                break;
            default: /* setting the dropdown height when active */
                this.dropdownHeight = `--height: ${
                    this.dropdown.nativeElement.scrollHeight
          + this.stylization.borderWidth * 2
                }px;`;
                break;
        }
        // this.dropdownHeight = `--height: ${this.awaysOpen ? 'auto' : (this.dropdown.nativeElement.scrollHeight + this.stylization.borderWidth * 2)}px;`;/* setting the dropdown height when active */
        this.applyStyles();

        if (!this.autoClose) return;

        this.dropdownKey = this.shared.toCloseWhenClickOutside(
            '.dropdown',
            this.dropdown,
            { _this: this, propertyToBeToggled: 'dropdownExpanded' }
        );
        this.dataId = Math.random().toString().replace('.', '');
        console.log('dropdownStyle ', this.dropdownStyle);

        this.cd.detectChanges();
    }

    toggleDropdown() {
        if (this.awaysOpen) return;

        if (this.initOpen) {
            this.initOpen = false;
        }
        this.calculateHeight();

        this.applyStyles();
        setTimeout(() => {
            this.dropdownExpanded = !this.dropdownExpanded;
            this.dropdown.nativeElement.scrollTop = 0;

            if (this.dropdownExpanded) {
                setTimeout(() => {
                    (this.dropdown.nativeElement.height = this.dropdownHeight = '--height: auto'),
                    this.applyStyles();
                }, 300);
            }
        }, 50);
    }

    calculateHeight() {
        const totalHeight = this.dropdown.nativeElement.scrollHeight
      + this.stylization.borderWidth * 2;
        this.dropdownHeight = `--height: ${totalHeight}px;`; /* setting the dropdown height when active */

        this.dropdown.nativeElement.style.animation = 'none';
        this.dropdown.nativeElement.style.overflow = 'hidden';
        this.cd.detectChanges();
    }

    applyStyles() {
        const { borderWidth, closed, expanded } = this.stylization;

        const borderWidthPx = `--border-width: ${borderWidth}px;`;

        const borderColorClosed = `--closed-border-color: ${closed.borderColor};`;
        const textColorClosed = `--closed-text-color: ${closed.textColor};`;
        const iconColorClosed = `--closed-icon-color: ${this.modals.hexToCSSFilter(
            closed.iconColor
        )};`;
        const fontWeightClosed = `--closed-font-weight: ${closed.fontWeight};`;
        const backgroundClosed = `--background-closed-color: ${closed.backgroundColor};`;

        const borderColorExpanded = `--expanded-border-color: ${expanded.borderColor};`;
        const textColorExpanded = `--expanded-text-color: ${expanded.textColor};`;
        const iconColorExpanded = `--expanded-icon-color: ${this.modals.hexToCSSFilter(
            closed.iconColor
        )};`;
        const fontWeightExpanded = `--expanded-font-weight: ${expanded.fontWeight};`;
        const backgroundExpanded = `--background-expanded-color: ${expanded.backgroundColor};`;

        this.dropdownStyle += `${borderWidthPx} ${borderColorClosed} ${textColorClosed} ${textColorExpanded}
                        ${iconColorClosed} ${iconColorExpanded} ${fontWeightClosed} ${fontWeightExpanded}
                        ${borderColorExpanded} ${backgroundClosed} ${backgroundExpanded}
                        ${this.dropdownHeight}`;

        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.dropdownKey !== undefined) this.shared.toCloseWhenClickOutside(this.dropdownKey);
    }
}
