import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class GradesGuard implements CanActivate {

  constructor(private sharedService: SharedService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    let i18n = this.sharedService.getTranslationsOf('Errors');
    let message = i18n.permission_unauthorized;

    if (route.routeConfig?.path == '') {
      if (!this.sharedService.checkPermission("grades_periods", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }

    if (route.routeConfig?.path == ':id') {
      if (!this.sharedService.checkPermission("grades", 'list')) {
        this.sharedService.showUnauthorizedModal(message);
        return false;
      }
    }
    return true;
  }
}
