<app-generic-modal [minWidth]="'30%'" [maxWidth]="'540px'" [isVisible]="true">
    <div>
        <header class="py-4 px-6">
            <p class="text-center font-medium text-22 md:text-26 text-customBlue-default">
                {{ title }}
            </p>
            <p class="text-center text-14 md:text-16 text-customGray-dark leading-4">
                {{ subtitle }}
            </p>
        </header>
    </div>

    <hr class="text-customBlue-glass" />

    <form [formGroup]="studentRegistersForm">
        <div class="px-6 py-2">
            <div class="font-medium text-16 text-customBlue-default">Aluno</div>

            <div
                class="relative flex items-center gap-2 border border-customGray-default rounded-md px-4 py-2 mb-2"
            >
                <div class="w-full">
                    <div
                        class="relative bg-customWhite-default text-customGray-default cursor-pointer flex justify-between items-center"
                        (click)="toggleDropdown()"
                    >
                        <div class=" w-full">
                            <div 
                                class="flex justify-start items-center gap-2 pr-6"
                                *ngIf="studentSelected"
                            >
                                <app-user-frame
                                    [style]="{
                                        width: '23px',
                                        height: '23px',
                                        font: '400 17px Roboto'
                                    }"
                                    [img_src]="studentSelected?.user?.image"
                                    [user_name]="studentSelected?.user?.name"
                                >
                                </app-user-frame>
                                <div
                                    pTooltip="{{ studentSelected?.user?.name }}"
                                    tooltipPosition="top"
                                    class="inline-block text-customGray-swampGray text-16 font-Roboto"
                                    style="
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    "
                                >
                                    {{ studentSelected?.user?.name }}
                                </div>
                            </div>

                            <div *ngIf="!studentSelected">
                                {{ i18n.class_diary_choose_student }}
                            </div>

                            <div 
                                *ngIf="frequency_users.length > 1"
                                class="absolute right-0"
                                style="top: 3px"
                            >
                                <span
                                    class="iconify w-4 h-4 text-customBlue-default"
                                    data-icon="bxs:down-arrow"
                                ></span>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="frequency_users.length > 1">
                        <ul
                            *ngIf="isOpen"
                            class="absolute left-0 mt-2 bg-customWhite-default border border-customGray-light shadow-lg z-10 container-scroll"
                            style="max-height: 220px; width: 100%"
                        >
                            <li
                                *ngFor="let user of frequency_users"
                                (click)="selectStudent(user)"
                                class="px-4 py-2 cursor-pointer text-14 font-Roboto"
                                [ngClass]="{
                                    'bg-customBlue-default text-customWhite-default hover:bg-customBlue-default':
                                        user === studentSelected,
                                    'hover:bg-customBlue-light text-customGray-grayThree':
                                        user !== studentSelected
                                }"
                            >
                                <div class="px-4">
                                    <div
                                        class="flex justify-start items-center gap-2"
                                    >
                                        <app-user-frame
                                            [style]="{
                                                width: '23px',
                                                height: '23px',
                                                font: '400 17px Roboto'
                                            }"
                                            [img_src]="user?.user?.image"
                                            [user_name]="user?.user?.name"
                                        >
                                        </app-user-frame>
                                        <div
                                            pTooltip="{{ user?.user?.name }}"
                                            tooltipPosition="top"
                                            class="inline-block"
                                            style="
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            "
                                        >
                                            {{ user?.user?.name }}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </ng-container>
                </div>
                
            </div>

            <div class="font-medium text-16 text-customBlue-default">
                {{ i18n.class_diary_reason }}
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 px-2">
                <ng-container
                    *ngFor="let reason of reasonsArray; let i = index"
                >
                    <div
                        class="flex items-center gap-3"
                    >
                        <div>
                            <label class="container cursor-pointer">
                                <input
                                    class="hidden"
                                    type="checkbox"
                                    (click)="checkReason(reason.id)"
                                    [checked]="isSelected(reason)"
                                />
                                <span class="check"></span>
                            </label>
                        </div>

                        <div 
                            pTooltip="{{ getReasonsTranslate(reason?.external_id) }}"
                            tooltipPosition="top"
                            class="text-customGray-dark text-14 mt-1"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        >
                            {{ getReasonsTranslate(reason?.external_id) }}
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="font-medium text-16 text-customBlue-default mt-4">
                {{ i18n.class_diary_message }}
            </div>
            <div class="font-normal text-12 text-customBlue-default">
                {{ i18n.class_diary_message_not_send_to_student }}
            </div>

            <textarea
                class="w-full border border-customGray-default rounded-sm p-2 mt-2 text-customGray-dark container-scroll"
                style="min-height: 156px"
                placeholder=""
                formControlName="note"
            ></textarea>
        </div>

        <div class="flex xs:flex-col items-center justify-center mt-2 mb-6 gap-2">
            <button
                class="border border-customBlue-default bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-16 px-6 py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                style="width: 152px"
                type="button"
                (click)="save()"
                [ngClass]="{
                    'disabled:opacity-20 cursor-not-allowed':
                        !studentRegistersForm.valid || !studentSelected
                }"
                [disabled]="!studentRegistersForm.valid || !studentSelected"
            >
                {{ i18n.class_diary_save }}
            </button>

            <button
                (click)="cancel()"
                class="text-customBlue-default border border-customBlue-default rounded-md background-transparent font-semibold px-6 py-2 text-16 outline-none focus:outline-none ease-linear transition-all duration-150"
                style="width: 152px"
                type="button"
            >
                {{ i18n.class_diary_cancel }}
            </button>
        </div>
    </form>
</app-generic-modal>
