import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(refDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const inputDate = control.value == null ? '' : control.value;
        if (inputDate.length !== 0 && inputDate < refDate) {
            return { dateError: true };
        }
        return null;
    };
}

export function maxValueValidator(maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let { value } = control;
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        value = parseFloat(value);

        if (value !== null && value !== undefined && value > maxValue) {
            return { maxValue: { value: control.value } };
        }
        return null;
    };
}

export function compareHourValidator(
    earlierControlName: string,
    laterControlName: string,
    currentLanguage
): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const earlierControl = formGroup.get(earlierControlName);
        const laterControl = formGroup.get(laterControlName);

        if (!earlierControl || !laterControl) {
            return null;
        }

        const [startHour] = earlierControl.value.split(':').map(Number);
        const [endHour] = laterControl.value.split(':').map(Number);
        const is24HourFormat = currentLanguage === 'en' ? 12 : 0;

        const earlierValue = (startHour + is24HourFormat) * 60;
        const laterValue = (endHour + is24HourFormat) * 60;

        // Check if both times are valid
        if ((earlierValue && laterValue) && (earlierValue >= laterValue)) {
            return { timesNotInOrder: true };
        }

        return null;
    };
}

export function compareTimesValidator(
    earlierControlName: string,
    laterControlName: string,
    currentLanguage
): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const earlierControl = formGroup.get(earlierControlName);
        const laterControl = formGroup.get(laterControlName);

        if (!earlierControl || !laterControl) {
            return null;
        }

        const [startHour, startMinutes] = earlierControl.value.split(':').map(Number);
        const [endHour, endMinutes] = laterControl.value.split(':').map(Number);
        const is24HourFormat = currentLanguage === 'en' ? 12 : 0;

        const earlierValue = (startHour + is24HourFormat) * 60 + startMinutes;
        const laterValue = (endHour + is24HourFormat) * 60 + endMinutes;

        if ((earlierValue > laterValue)) {
            return { timesNotInOrder: true };
        }

        return null;
    };
}

export function timeFormatValidator(currentLanguage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const time = control.value;
        if (!time) {
            return null;
        }

        const is24HourFormat = /^(?:[01]\d|2[0-3]):[0-5]\d$/; // 24-hour format HH:mm
        const is12HourFormat = /^(0?[1-9]|1[0-2]):[0-5]\d\s?(AM|PM|am|pm)$/; // 12-hour format h:mm AM/PM

        const isValid = currentLanguage === 'en'
            ? is12HourFormat.test(time)
            : is24HourFormat.test(time);

        return isValid ? null : { invalidTimeFormat: true };
    };
}

function parseTime(time: string): number | null {
    const removeSuffix12HourFormat = (timeString: string): string => {
        if (timeString.includes('AM') || timeString.includes('PM')) {
            return timeString.replace(/\s?(AM|PM)/gi, '').trim();
        }

        return timeString;
    };

    const toMinutes = (timeString: string) => {
        const noSuffixTime = removeSuffix12HourFormat(timeString);
        const [hours, minutes] = noSuffixTime.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const timeInMinutes = toMinutes(time);

    if (timeInMinutes) {
        return timeInMinutes;
    }

    return null;
}

export function rangeTimeValidator(minTime?: string, maxTime?: string,): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const inputTime = control.value == null ? '' : control.value.trim();

        if (!inputTime) {
            return null;
        }

        const minDate = minTime ? parseTime(minTime) : null;
        const maxDate = maxTime ? parseTime(maxTime) : null;

        const inputTimeInMinutes = parseTime(inputTime);

        if (!inputTimeInMinutes || (minTime && !minTime) || (maxTime && !maxTime)) {
            return null;
        }

        if ((minDate && inputTimeInMinutes < minDate) || (maxDate && inputTimeInMinutes > maxDate)) {
            return { timeError: { minTime, maxTime } };
        }

        return null;
    };
}
