import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SettingsAdvancedService } from 'src/app/pages/settings/components/settings-advanced/settings-advanced.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { OrgUnitsDataFormat } from 'src/app/shared/org-units-data-format.service';
import { SharedService } from 'src/app/shared/shared.service';

import { MessengerToastService } from '../../components/messenger-toast/messenger-toast.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { DisciplinesModelsService } from '../../shared/disciplines-models.service';
import { OrgUnitsService } from '../org-units/org-units.service';

@Component({
    selector: 'app-disciplines-models',
    templateUrl: './disciplines-models.component.html',
    styleUrls: ['./disciplines-models.component.scss'],
})
export class DisciplinesModelsComponent implements OnInit {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    paginationParams: any = {};
    activePage = 1;
    per_page = 8;

    searchForm: UntypedFormGroup;
    types: any = '';
    segment: any = '';
    query: any = '';
    modalities: any = '';
    months: any = '';
    years: any = '';
    selectUndefinedOptionValue: any = '';

    dataDisciplines: any = [];
    disciplnesToModal: any = [];
    isLoadingData: boolean;
    sections: any = [];
    filterOpen: any;
    showAddDiscipline: boolean;
    showImportDiscipline: boolean;
    orgUnits: any;
    user: any;
    loading = true;
    disciplineCode: string;

    counter: any = 0;
    timer: any = [];
    timeStatus: any;
    timerObs = new Subject<any>();

    filterOrgUnits: any = [];
    postDisciplineParams: any;

    selectedNodes1: any = [];

    i18n: any;
    currentLanguage: any;

    isImportModelOpen$ = this.disciplinesModelsService.isImportModelOpen$;
    isExportContentsModalOpen$ = this.disciplinesModelsService.isExportContentsModalOpen$;
    isDuplicateDisciplineOpen$ = this.disciplinesModelsService.isDuplicateDisciplineOpen$;

    isImportModelClassroom$ = this.disciplinesModelsService.isImportModelClassroomOpen$;
    isLoadingModal$ = this.disciplinesModelsService.isLoadingModal$;
    isLoading$ = this.disciplinesModelsService.isLoading$;

    isselectedExportOrImport = false;

    segmentSelect: any = [];
    modalitiesSelect: any = [];
    yearsSelect: any = [
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
    ];
    monthsSelect: any = [];

    templates: [] = [];
    modelsInitiated = false;

    isImporting = false;
    messageImporting = 'Importação em andamento';

    enableImportClassroom = false;
    enableScopesClassroom = false;
    CanSubject = false;
    showClassroomImport = false;
    isNotStandalone = false;

    settingsAdvanced: any = [];

    isModalConfirmClassroomOpen = false;
    messageModalConfirmClassroom = '';
    btnTextModalConfirmClassroom = '';

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        private orgUnitsDataFormat: OrgUnitsDataFormat,
        public platModalService: PlatformModalsService,
        private orgUnitsService: OrgUnitsService,
        private sharedService: SharedService,
        private formBuilder: UntypedFormBuilder,
        private toastMessenger: MessengerToastService,
        private localStorageService: LocalStorageService,
        private settingsAdvancedService: SettingsAdvancedService,
        private cd: ChangeDetectorRef,
    ) {
        this.createSearchForm();
    }

    ngOnInit(): void {
        this.isNotStandalone = this.localStorageService.isNotStandalone();
        this.getLoading();
        this.getTranslations();
        this.getUser();
        this.getLoading();
        this.getOrgUnits();
        this.getModalities();
        this.getSegment();
        this.getClassroomDisciplineLog();
        this.getClassroomSettings();
        // this.getDisciplinesToModal();
        // this.getSections('matematica');
    }
    @ViewChild('search_discipline') search_discipline: ElementRef;
    disciplines_search_source: any;

    ngAfterViewInit(): void {
        // Verifica se contém a chave
        if (this.localStorageService.getLocalStorageItem('searchKeys')) {
            const storedSearchValue = JSON.parse(
                localStorage.getItem('searchKeys')!
            ).keyDisciplinesModel;
            // Coloca o valor no input e na query e depois pesquisa
            this.searchForm.controls.query.setValue(storedSearchValue);
            this.query = storedSearchValue;
            this.searchDiscipline();
        } else {
            // Não tiver chave, seta ela como vazio e pesquisa (buscar todos)
            this.localStorageService.searchKeys.keyDisciplinesModel = '';
            localStorage.setItem(
                'searchKeys',
                JSON.stringify(this.localStorageService.searchKeys)
            );
            this.searchDiscipline();
        }
    }

    selectedDisciplineToImport(disciplineCode: string) {
        this.disciplineCode = disciplineCode;
        this.selectExportOrImport(true);
    }

    closeModalAdd($event) {
        this.showAddDiscipline = $event;
    }

    openModalAdd() {
        this.showAddDiscipline = !this.showAddDiscipline;
    }

    getLoading() {
        this.isLoading$.subscribe((loading) => (this.loading = loading));
    }

    loadingModalTrue() {
        this.disciplinesModelsService.isLoadingModalTrue();
    }

    getClassroomDisciplineLog() {
        this.disciplinesModelsService.getClassroomDisciplineLog().subscribe(
            (data) => {
                if (Object.keys(data).length > 0) {
                    this.isImporting = true;
                    this.messageImporting = data?.type == 'export'
                        ? this.i18n.disciplines_pendding_export_classroom
                        : this.i18n.disciplines_pendding_import_classroom;
                }
            },
            (err) => console.log('ERRO: ', err.error.message)
        );
    }

    loadingModalFalse() {
        this.disciplinesModelsService.isLoadingModalFalse();
    }

    openModalClassroomImport(): void {
        this.isselectedExportOrImport = false;
        this.disciplinesModelsService.addModelModalClassroomOpen();
    }

    loadingTrue() {
        this.platModalService.toggle('loading');
    }

    loadingFalse() {
        this.platModalService.close('loading');
    }

    async searchDiscipline() {
        // Pega o que o usuário digitou e salva no storage e pesquisa
        this.query = await this.sharedService.debounce(
            this.searchForm.controls.query.value
        );
        this.localStorageService.searchKeys.keyDisciplinesModel = this.query;
        localStorage.setItem(
            'searchKeys',
            JSON.stringify(this.localStorageService.searchKeys)
        );

        this.activePage = 1;
        this.getDisciplines(
            'template',
            this.query,
            this.segment,
            this.modalities,
            this.months,
            this.years,
            this.activePage,
            this.per_page,
            true
        );
    }

    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Dashboard'),
            ...this.sharedService.getTranslationsOf('Reports'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
        this.monthsSelect = [
            this.i18n.dash_jan,
            this.i18n.dash_feb,
            this.i18n.dash_mar,
            this.i18n.dash_April,
            this.i18n.dash_may,
            this.i18n.dash_june,
            this.i18n.dash_jul,
            this.i18n.dash_aug,
            this.i18n.dash_sept,
            this.i18n.dash_oct,
            this.i18n.dash_nov,
            this.i18n.dash_dec,
        ];

        this.messageImporting = this.i18n.disciplines_pendding_import_classroom;
    }

    getOrgUnits() {
        this.orgUnitsService.getOrgUnits().subscribe((orgUnits) => {
            this.orgUnits = this.orgUnitsDataFormat.jsonReorder(orgUnits);
        });
    }

    getSegment() {
        this.disciplinesModelsService
            .getDisciplineLevels()
            .subscribe((segmentSelect) => {
                this.segmentSelect = segmentSelect;
            });
    }

    getModalities() {
        this.disciplinesModelsService
            .getDisciplineModalities()
            .subscribe((modalitiesSelect) => {
                this.modalitiesSelect = modalitiesSelect;
            });
    }

    getDisciplines(
        type: any,
        query: any,
        segment: any,
        modalities: any,
        months: any,
        years: any,
        activePage: any,
        per_page: any,
        notShowIsLoading?: boolean
    ) {
        if (notShowIsLoading == undefined) {
            this.loadingTrue();
        }
        this.disciplinesModelsService
            .getDisciplines(
                type,
                query,
                segment,
                modalities,
                months,
                years,
                activePage,
                per_page
            )
            .subscribe({
                next: (models) => {
                    this.modelsInitiated = true;
                    this.loadingFalse();
                    this.dataDisciplines = models.items;

                    if (this.dataDisciplines?.length) {
                        this.refreshPagination(models);
                    }
                },

                error: (err) => {
                    this.loadingFalse();
                    this.platModalService.toggle(
                        'message',
                        this.i18n.disciplines_have_an_error + err.error.errCode,
                        'close'
                    );
                },
            });
    }

    goToPage({ activePage }): void {
        const page = activePage;

        this.disciplinesModelsService
            .getDisciplines(
                'template',
                this.query,
                this.segment,
                this.modalities,
                this.months,
                this.years,
                page,
                this.per_page
            )
            .subscribe({
                next: (response) => {
                    this.dataDisciplines = response.items;
                    this.activePage = response.page;
                },
            });
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    getSections(discipline: any) {
        this.loadingModalTrue();
        const replicationPermission = '?replication_permission=true';
        this.disciplinesModelsService.getSections(discipline, replicationPermission).subscribe(
            (data) => {
                console.log('data ', data);

                this.sections = data;
            },
            (err) => console.log('ERRO: ', err.error.message),
            () => {
                this.loadingModalFalse();
            }
        );
    }

    getDisciplinesToModal(event) {
        let { query } = event;
        query == undefined ? (query = '') : (query = query);

        let { type } = event;
        type == undefined ? (type = 'private') : (type = type);

        this.loadingModalTrue();
        this.disciplinesModelsService
            .getDisciplines(
                type,
                query,
                this.segment,
                this.modalities,
                this.months,
                this.years,
                event.page,
                event.per_page
            )
            .subscribe(
                (data) => {
                    this.disciplnesToModal = data;
                    setTimeout(() => {
                        this.loadingModalFalse();
                    }, 2000);
                },
                (err) => console.log('ERRO: ', err.error.message),
                () => console.log('COMPLETE DISCIPLINES')
            );
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            query: [''],
            courseFilter: [''],
            segmentFilter: [''],
            modalityFilter: [''],
            monthsFilter: [''],
            yearsFilter: [''],
        });
    }

    filterDiscipline() {
        (this.segment = this.searchForm.controls.segmentFilter.value),
            (this.modalities = this.searchForm.controls.modalityFilter.value),
            (this.months = this.searchForm.controls.monthsFilter.value),
            (this.years = this.searchForm.controls.yearsFilter.value),
            (this.activePage = 1);

        this.getDisciplines(
            'template',
            this.query,
            this.segment,
            this.modalities,
            this.months,
            this.years,
            this.activePage,
            this.per_page
        );
    }

    receivesDisciplineDataAndPost(event: any) {
        this.loadingModalTrue();
        this.postDiscipline(event);
        this.showAddDiscipline = false;
    }

    receivesDiscipline(event: any) {
        this.postDiscipline(event);
        this.showAddDiscipline = false;
    }

    resourcesData;
    receivesImportTemplate(event: any) {
        this.isDuplicate = false;
        console.log('RECEBEU IMPORT', event);
        this.resourcesData = event;
        console.log('this.resourcesData ', this.resourcesData);

        this.templates = event.targetDisciplines;
        let { keepDisciplineInSync } = event;
        keepDisciplineInSync == true
            ? (keepDisciplineInSync = false)
            : (keepDisciplineInSync = true);
        const { sourceContents } = event;
        const { targetDisciplines } = event;
        const { sourceSections } = event;
        const targetSections = !event.targetSections.length
            ? null
            : event.targetSections;

        this.paramsToImport = {
            keepDisciplineInSync,
            sourceContents,
            targetDisciplines,
            sourceSections,
            targetSections,
            keepDates: this.datesResourcesValues
        };
        console.log('this.paramsToImport ', this.paramsToImport);

        // this.cd.detectChanges();
    }

    // receivesImportData(event) {
    //     this.postToImportDiscipline(this.paramsToImport, this.disciplineCode);
    // }

    receivesExportDataToReplicate(exportsData) {
        this.postToImportDiscipline(this.paramsToImport, this.disciplineCode);
    }

    paramsToImport: any = {};

    messageImport() {
        this.toastMessenger.messengerToast(
            'load',
            this.i18n.disciplines_loading_import,
            true
        );
    }

    receivesExport(event: any) {
        const { type } = event;

        const params = {
            keepDisciplineInSync: event.keepDisciplineInSync,
            sourceContents: event.sourceContents,
            targetDisciplines: event.targetDisciplines,
            sourceSections: event.sourceSections,
            targetSections: event?.targetSections ?? null,
            keepDates: this.datesResourcesValues,
            availabe_mode: event?.availabe_mode,
        };

        if (type == 'private') {
            this.postToImportDiscipline(params, this.disciplineCode);
            this.disciplinesModelsService.addModelModalClassroomClose();

            return;
        }

        this.postToExportClassroomDisciplines(params);
    }

    receivesImportClassroom(id: any) {
        this.messageImport();

        const params = {
            external_id: id,
        };

        this.disciplinesModelsService
            .postImportDisciplineClassRoom(params)
            .subscribe(
                () => {
                    setTimeout(() => {
                        this.disciplinesModelsService.addModelModalClassroomClose();
                        this.callGetDisciplines('template');
                        this.toastMessenger.messengerToast(
                            'success',
                            this.i18n.disciplines_loading_import,
                            true
                        );

                        this.isModalConfirmClassroomOpen = true;
                        this.messageModalConfirmClassroom = this.i18n.disciplines_import_pendding;
                        this.getClassroomDisciplineLog();
                    }, 2000);
                },
                (err) => this.disciplinesModelsService.showMessage(
                    this.i18n.disciplines_have_an_error,
                    this.i18n.disciplines_error_message + err.error.message,
                    'toast-error'
                ),
                () => { }
            );
    }

    postToImportDiscipline(templateData, disciplineCode) {
        // NÃO EXCLUIR COMENTÁRIO
        // this.getStatusImport(disciplineCode);
        this.toastMessenger.messengerToast(
            'load',
            this.i18n.disciplines_loading_import,
            true
        );
        this.disciplinesModelsService
            .postImportDisciplineOrigin(templateData, disciplineCode)
            .subscribe(
                () => {
                    setTimeout(() => {
                        this.datesResourcesValues = {};
                        this.toastMessenger.messengerToast(
                            'success',
                            this.i18n.disciplines_export_success,
                            true
                        );

                        this.callGetDisciplines('template');
                    }, 2000);
                },
                (err) => this.disciplinesModelsService.showMessage(
                    this.i18n.disciplines_have_an_error,
                    this.i18n.disciplines_error_message + err.error.message,
                    'toast-error'
                ),
                () => { }
            );
    }

    postToExportClassroomDisciplines(params) {
        this.toastMessenger.messengerToast(
            'load',
            this.i18n.disciplines_loading_export,
            true
        );
        this.disciplinesModelsService
            .postToExportClassroomDisciplines(params)
            .subscribe(
                () => {
                    setTimeout(() => {
                        this.toastMessenger.messengerToast(
                            'success',
                            this.i18n.disciplines_export_success,
                            true
                        );
                        this.isModalConfirmClassroomOpen = true;
                        this.messageModalConfirmClassroom = this.i18n.disciplines_export_pedding;
                        this.disciplinesModelsService.addModelModalClassroomClose();
                    }, 2000);
                },
                (err) => this.toastMessenger.messengerToast(
                    'error',
                    this.i18n.disciplines_export_error,
                    true
                ),
                () => { }
            );
    }

    getDisciplinesToModalClassroom(event) {
        let { query } = event;
        query == undefined ? (query = '') : (query = query);

        this.loadingModalTrue();
        this.disciplinesModelsService
            .getDisciplines(
                'google_classroom',
                query,
                this.segment,
                this.modalities,
                this.months,
                this.years,
                event.page ?? 1,
                event.per_page ?? 5
            )
            .subscribe(
                (data) => {
                    this.disciplnesToModal = data;
                    setTimeout(() => {
                        this.loadingModalFalse();
                    }, 2000);
                },
                (err) => console.log('ERRO: ', err.error.message),
                () => console.log('COMPLETE DISCIPLINES')
            );
    }

    postDiscipline(disciplineParams) {
        this.loadingTrue();
        this.disciplinesModelsService
            .postDisciplines(disciplineParams)
            .pipe(
                finalize(() => {
                    this.loadingModalFalse();
                })
            )
            .subscribe(
                () => {
                    this.callGetDisciplines('template');
                    this.selectedNodes1 = [];
                    // this.isLoadingData = false
                    this.disciplinesModelsService.showMessage(
                        this.i18n.disciplines_ok,
                        this.i18n.disciplines_success_add_template,
                        'toast-success'
                    );
                },
                (err) => {
                    this.loadingFalse();
                    console.log(err);
                    switch (err.status) {
                        case 409:
                            this.platModalService.toggle(
                                'message',
                                this.i18n.discipline_already_exists,
                                'close'
                            );
                            break;
                        default:
                            this.platModalService.toggle(
                                'message',
                                `${this.i18n.disciplines_have_an_error} ${err.status}`,
                                'close'
                            );
                            break;
                    }
                }
            );
    }

    isDuplicate = false;
    getResourcesDatesInDuplicate(discipline_external_id: string): Promise<any> {
        // send empty to search all content and section in discipline
        const sectionsAndContents = '';

        return new Promise((resolve, reject) => {
            this.disciplinesModelsService.getDatesByResources(discipline_external_id, sectionsAndContents).subscribe({
                next: (resourcesDates: any) => {
                    resolve(resourcesDates);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    preRegisteredDates;
    templateMode;
    disciplineExternalId;
    verifyPreRegisteredDatesToPostDuplicate(discipline_external_id) {
        this.templateMode = 'preRegisteredSelectionMode';
        this.disciplineExternalId = discipline_external_id;
        this.disciplinesModelsService.openDuplicateDiscipline();
    }

    postDuplicateDiscipline(discipline_external_id) {
        this.toastMessenger.messengerToast(
            'load',
            'Duplicando disciplina',
            true
        );
        console.log('CHAMA Duplicate', discipline_external_id);
        const params = {
            keepDates: this.datesResourcesValues
        };
        this.disciplinesModelsService
            .postDuplicate(discipline_external_id, params)
            .pipe(
                finalize(() => {
                    this.loadingModalFalse();
                    this.isDuplicate = false;
                })
            )
            .subscribe({
                next: () => {
                    this.callGetDisciplines('template');
                    this.selectedNodes1 = [];
                    this.toastMessenger.messengerToast(
                        'success',
                        this.i18n.disciplines_duplicate_success,
                        true
                    );
                },
                error: (err) => {
                    console.log(err);
                    switch (err.status) {
                        case 409:
                            this.platModalService.toggle('message', this.i18n.discipline_already_exists, 'close');
                            break;
                        default:
                            this.platModalService.toggle('message', this.i18n.disciplines_duplicate_error_default, 'close');
                            break;
                    }
                },
                complete: () => {
                    this.disciplineExternalId = null;
                    this.templateMode = '';
                }
            });
    }

    callGetDisciplines(type) {
        this.getDisciplines(
            type,
            this.query,
            this.segment,
            this.modalities,
            this.months,
            this.years,
            this.activePage,
            this.per_page
        );
    }

    remove(id: any, deleteNow) {
        if (deleteNow) {
            this.disciplinesModelsService.deleteDisciplines(id).subscribe(
                (response) => {
                    this.callGetDisciplines('template');
                    this.disciplinesModelsService.showMessage(
                        this.i18n.disciplines_remove_success_title,
                        this.i18n.disciplines_remove_success_message,
                        'toast-success'
                    );
                },
                (err) => {
                    this.loadingFalse();
                    this.platModalService.toggle('message', err.error.errCode, 'close');
                }
            );
        } else {
            this.platModalService.toggle('decision', 'deletion_subject_discipline', {
                forward: () => {
                    this.remove(id, true);
                    this.loadingTrue();
                },
                finally: () => {
                    this.platModalService.close('decision');
                },
            });
        }
    }

    translationsSegment(segment_id: string, segment_name: string) {
        const segmentDefault = segment_name;
        switch (segment_id) {
            case 'educacao-basica-ensino-fundamental-series-iniciais':
                return this.textCut(
                    47,
                    this.i18n?.educacao_basica_ensino_fundamental_series_iniciais
                );
            case 'educacao-basica-ensino-fundamental-series-finais':
                return this.textCut(
                    47,
                    this.i18n?.educacao_basica_ensino_fundamental_series_finais
                );
            case 'educacao-basica-ensino-medio':
                return this.textCut(47, this.i18n?.educacao_basica_ensino_medio);
            case 'educacao-basica-ensino-tecnico-de-nivel-medio':
                return this.textCut(
                    47,
                    this.i18n?.educacao_basica_ensino_tecnico_de_nivel_medio
                );
            case 'educacao-superior-bacharelado':
                return this.textCut(47, this.i18n?.educacao_superior_bacharelado);
            case 'educacao-superior-licenciatura':
                return this.textCut(47, this.i18n?.educacao_superior_licenciatura);
            case 'educacao-superior-tecnologia':
                return this.textCut(47, this.i18n?.educacao_superior_tecnologia);
            case 'educacao-superior-especializacao':
                return this.textCut(47, this.i18n?.educacao_superior_especializacao);
            case 'educacao-superior-mestrado':
                return this.textCut(47, this.i18n?.educacao_superior_mestrado);
            case 'educacao-superior-doutorado':
                return this.textCut(47, this.i18n?.educacao_superior_doutorado);
            case 'projeto-especial':
                return this.textCut(47, this.i18n?.projeto_especial);
            default:
                return this.textCut(47, segmentDefault);
        }
    }

    translationsModality(modality_id: any, modality_name: string) {
        const modalityDefault = modality_name;
        switch (modality_id) {
            case 'presencial':
                return this.textCut(47, this.i18n?.presencial);
            case 'semi-presencial':
                return this.textCut(47, this.i18n?.semi_presencial);
            case 'ead':
                return this.textCut(47, this.i18n?.ead);
            default:
                return this.textCut(47, modalityDefault);
        }
    }

    textCut(limit: any, textAnnouncement: any) {
        let text = textAnnouncement;
        const overLimit = text.length > limit;
        const elipsisOrEmpty = overLimit ? '...' : '';
        text = text.substring(0, limit) + elipsisOrEmpty;
        return text;
    }

    getSectionsByDisciplines(discipline: string) {
        this.getSections(discipline);
    }

    getTemplatesSections() {
        this.templates?.forEach((template) => {
            this.getSections(template);
        });
    }

    selectExportOrImport(toggle: boolean): void {
        this.isselectedExportOrImport = toggle;
    }

    getClassroomSettings() {
        this.settingsAdvancedService.getClassroomSettings().subscribe(
            (settingsClassroom) => {
                this.enableImportClassroom = settingsClassroom?.data?.enable_import == '1';
                this.enableScopesClassroom = settingsClassroom?.data?.enable_scopes == '1';
                this.CanSubject = settingsClassroom?.data?.can_be_subject;

                if (this.isNotStandalone) {
                    this.showClassroomImport = this.enableImportClassroom && this.enableScopesClassroom && this.CanSubject;
                } else {
                    this.showClassroomImport = false;
                }
            },
            (err) => console.log(err)
        );
    }

    datesResourcesValues;
    getDatesResourcesValues(datesResourcesValues) {
        this.datesResourcesValues = datesResourcesValues;
    }

    closeModalConfirmClassroom(event) {
        this.isModalConfirmClassroomOpen = false;
        this.messageModalConfirmClassroom = '';
    }
}
