<button
    *ngIf="status === 'present'"
    (click)="handleChangeStatus(status)"
    class="cursor-pointer icon-present"
    [ngClass]="{
        'disabled:opacity-20 cursor-not-allowed':
            isDisabled
    }"
    [disabled]="isDisabled"
></button>
<button
    *ngIf="status === 'absent'"
    (click)="handleChangeStatus(status)"
    class="cursor-pointer icon-absent"
    [ngClass]="{
        'disabled:opacity-20 cursor-not-allowed':
            isDisabled
    }"
    [disabled]="isDisabled"
></button>
<button
    *ngIf="status === '1/2_presence'"
    (click)="handleChangeStatus(status)"
    class="cursor-pointer icon-neutral"
    [ngClass]="{
        'disabled:opacity-20 cursor-not-allowed':
            isDisabled
    }"
    [disabled]="isDisabled"
></button>
