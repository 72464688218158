<div class="pl-4 pr-2 py-2">
    <div class="font-Montserrat font-bold text-customBlue-default text-12 mb-4">
        {{ i18n.class_diary_class_details }}
    </div>

    <div class="container-scroll pr-2">

        <div>
            <label class="text-14 text-customGray-swampGray"> {{ i18n.class_diary_class_date }} </label>
    
            <div>
                <app-calendar-picker
                    [currentLanguage]="currentLanguage"
                    [frequencyCalendar]="frequencyCalendar"
                    [inputDate]="inputDate"
                    (emitEvent)="handleChangeDate($event)" 
                    (emitGetFrequencyCalendar)="changeMonth($event)"
                ></app-calendar-picker>
            </div>
        </div>
    
        <div class="mt-2 mb-6">
            <label class="text-14 text-customGray-swampGray"> {{ i18n.class_diary_class_hour }}* </label>
    
            <div
                class="flex items-center gap-2 border border-dashed border-customGray-default rounded-sm text-left p-2"
            >
                <div 
                    #classDetailsDropdown
                    class="relative w-full"
                >
                    <div
                        class="bg-customWhite-default text-customGray-default cursor-pointer flex justify-between items-center pr-6"
                        (click)="toggleDropdown()"
                    >
                        <div
                            class="relative flex justify-between w-full gap-2 text-customGray-grayThree text-14 leading-5"
                        >
                            <div 
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                pTooltip="{{ selectedValue?.name }}"
                                tooltipPosition="top"
                            >
                                {{ selectedValue?.name || i18n.class_diary_choose_class }}
                            </div>
    
                            <ng-container *ngIf="selectedValue">
                                <div class="flex items-center gap-2">
                                    <div>
                                        <span
                                            class="iconify-inline w-4 h-4 text-customGray-grayThree font-Roboto"
                                            data-icon="iconamoon:clock-light"
                                        ></span>
                                    </div>
        
                                    <div>
                                        {{
                                            toStringHourAndMinutes(
                                                selectedValue?.start_time
                                            )
                                        }}
                                    </div>
                                </div>
        
                                <div class="flex justify-center items-center">
                                    <span
                                        class="iconify-inline w-4 h-4"
                                        data-icon="bi:dash"
                                    ></span>
                                </div>
        
                                <div class="flex items-center gap-2">
                                    <div>
                                        <span
                                            class="iconify-inline w-4 h-4 text-customGray-grayThree font-Roboto"
                                            data-icon="iconamoon:clock-light"
                                        ></span>
                                    </div>
        
                                    <div>
                                        {{
                                            toStringHourAndMinutes(
                                                selectedValue?.end_time
                                            )
                                        }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
    
                        <div 
                            class="absolute"
                            style="right: 0;"
                        >
                            <span
                                class="iconify w-4 h-4 text-customBlue-default"
                                data-icon="bxs:down-arrow"
                            ></span>
                        </div>
                    </div>
    
                    <ul
                        *ngIf="isOpen"
                        class="absolute mt-2 bg-customWhite-default border border-customGray-light shadow-lg z-10 container-scroll"
                        style="max-height: 220px; width: 100%;"
                    >
                        <li
                            *ngFor="let class of classScheduleDetails"
                            (click)="selectOption(class)"
                            class="px-4 py-2 cursor-pointer text-14 font-Roboto"
                            [ngClass]="{
                                'bg-customBlue-default text-customWhite-default hover:bg-customBlue-default':
                                    class === selectedValue,
                                'hover:bg-customBlue-light text-customGray-grayThree':
                                    class !== selectedValue
                            }"
                        >
                            <div class="flex justify-between gap-2 leading-6">
                                <div 
                                    pTooltip="{{ class.name }}"
                                    tooltipPosition="top"
                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                >
                                    {{ class.name }}
                                </div>
                                
                                <div class="flex justify-between gap-2 ">
                                    <div 
                                        class="flex items-center gap-2"
                                        style="min-width: 87px;"
                                    >
                                        <div>
                                            <span
                                                class="iconify-inline w-4 h-4"
                                                data-icon="iconamoon:clock-light"
                                            ></span>
                                        </div>
        
                                        <div>
                                            {{
                                                toStringHourAndMinutes(class.start_time)
                                            }}
                                        </div>
                                    </div>
        
                                    <div class="flex justify-center items-center">
                                        <span
                                            class="iconify-inline w-4 h-4"
                                            data-icon="bi:dash"
                                        ></span>
                                    </div>
        
                                    <div 
                                        class="flex items-center gap-2"
                                        style="min-width: 87px;"
                                    >
                                        <div>
                                            <span
                                                class="iconify-inline w-4 h-4"
                                                data-icon="iconamoon:clock-light"
                                            ></span>
                                        </div>
        
                                        <div>
                                            {{ toStringHourAndMinutes(class.end_time) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    
        <div>
            <div class="text-14 text-customGray-swampGray">{{ i18n.class_diary_intended_content }}</div>
        
            <textarea
                class="w-full border border-customGray-default rounded-sm p-2 mt-2 text-customGray-dark container-scroll"
                style="min-height: 104px"
                placeholder=""
                [(ngModel)]="classRegisterObject.intended_content"
                (ngModelChange)="updateInput($event, 'intended_content')"
            ></textarea>
        </div>

        <div class="mt-2">
            <div class="text-14 text-customGray-swampGray">{{ i18n.class_diary_realized_content }}</div>
        
            <textarea
                class="w-full border border-customGray-default rounded-sm p-2 mt-2 text-customGray-dark container-scroll"
                style="min-height: 104px"
                placeholder=""
                [(ngModel)]="classRegisterObject.covered_content"
                (ngModelChange)="updateInput($event, 'covered_content')"
            ></textarea>
        </div>
    

        <div>
            <div class="text-14 text-customGray-swampGray mt-4">{{ i18n.class_diary_occurrence }}</div>
        
            <div class="flex justify-center mt-2 mb-6 gap-2">
                <button
                    (click)="openModalOcurrences()"
                    class="w-full text-customBlue-default border border-customBlue-default rounded-md background-transparent font-bold px-6 py-2 text-12 outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    [ngClass]="{
                        'disabled:opacity-20 cursor-not-allowed':
                            classDiaryData.frequency.class_schedule_detail.id === null
                    }"
                    [disabled]="classDiaryData.frequency.class_schedule_detail.id === null"
                >
                    {{ i18n.class_diary_write_occurrence }}
                </button>
            </div>
        </div>
        
    </div>
</div>
