import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';

import { ClassDiaryService } from '../../class-diary.service';

@Component({
    selector: 'app-frequency',
    templateUrl: './frequency.component.html',
    styleUrls: ['./frequency.component.scss']
})
export class FrequencyComponent implements OnChanges {
    @Input() classDiaryData;
    @Output() userSelected = new EventEmitter();
    @Output() attendStatus = new EventEmitter();

    i18n;
    constructor(
        private classDiaryService: ClassDiaryService
    ) {
        this.i18n = classDiaryService.i18n;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            // console.log('changes.classDiaryData ', changes.classDiaryData);
        }
    }

    onStatusChange(attend_status: any) {
        this.attendStatus.emit(attend_status);
    }

    openModalAbsenceNotes(frequency_user) {
        this.userSelected.emit(frequency_user);
        this.classDiaryService.isModalStudentRegistersOpen$.next(true);
    }
}
