import {
    Component, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { slideInOutAnimation } from 'src/app/utils/animations/animations';

import { ClassDiaryService } from './class-diary.service';

@Component({
    selector: 'app-class-diary',
    templateUrl: './class-diary.component.html',
    styleUrls: ['./class-diary.component.scss'],
    animations: [
        slideInOutAnimation('300'),
    ],
})
export class ClassDiaryComponent implements OnInit, OnChanges {
    isClassDiaryOpen = false;

    classDiaryData;
    disciplineId;

    isModalStudentRegistersOpen$ = this.classDiaryService.isModalStudentRegistersOpen$;

    i18n;
    constructor(
        private classDiaryService: ClassDiaryService,
        private route: ActivatedRoute,
        private toastrService: ToastrService,
    ) {
        this.i18n = classDiaryService.i18n;
    }

    ngOnInit(): void {
        this.getUrlParams();
        this.subscriptionFrequencyCalendar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            // console.log('changes ', changes);
        }
    }

    getUrlParams() {
        this.route.params.subscribe({
            next: (params: any) => {
                if (Object.prototype.hasOwnProperty.call(params, 'id')) {
                    this.disciplineId = params.id;
                    this.getClassDiary(this.disciplineId, '');
                }
            }
        });
    }

    subscriptionFrequencyCalendar() {
        this.classDiaryService.frequencyCalendar$.subscribe({
            next: (frequencyCalendar) => {
                this.getFrequencyRegisterCalendar(frequencyCalendar);
            }
        });
    }

    getClassDiary(disciplineId, params) {
        this.classDiaryService.getClassDiary(disciplineId, params).subscribe({
            next: (res) => {
                this.classDiaryData = res.data;

                const classDetailIndex = this.classDiaryData.class_schedule_details.findIndex((classDetail) => classDetail.id === this.classScheduleDetailId);

                if (classDetailIndex !== -1) {
                    this.classDiaryData.frequency.class_schedule_detail = this.classDiaryData.class_schedule_details[classDetailIndex];
                }
            }
        });
    }

    toggleClassDiary() {
        this.isClassDiaryOpen = !this.isClassDiaryOpen;
        this.toggleLibrasVisibility();
        this.toggleUserWayVisibility();
    }

    handleCloseSidebar() {
        this.toggleClassDiary();
    }

    toggleLibrasVisibility() {
        const librasComponents = document.getElementsByClassName('container_libras');
        if (librasComponents.length > 0) {
            const containerLibras = librasComponents[0] as HTMLElement;
            if (this.isClassDiaryOpen) {
                containerLibras.style.display = 'none';
            } else {
                containerLibras.style.display = 'block';
            }
        }
    }

    toggleUserWayVisibility() {
        const userWayComponents = document.getElementsByClassName('userway_buttons_wrapper');
        if (userWayComponents.length > 0) {
            const containerUserWay = userWayComponents[0] as HTMLElement;
            if (this.isClassDiaryOpen) {
                containerUserWay.style.display = 'none';
            } else {
                containerUserWay.style.display = 'block';
            }
        }
    }

    modalObject;
    reasonsArray: any = [];
    receiveModalObject(modalObject) {
        this.modalObject = {
            frequency_users: this.classDiaryData.frequency_users,
            type: modalObject.type
        };
        if (Object.prototype.hasOwnProperty.call(modalObject, 'frequency_user')) {
            this.modalObject = {
                ...this.modalObject,
                userSelected: modalObject
            };
        }
        this.reasonsArray = this.classDiaryData[modalObject.type];
    }

    receiveUpdateDate(classRegisters) {
        const date = classRegisters?.date;
        const params = `date=${date}`;

        this.getClassDiary(this.disciplineId, params);
    }

    classScheduleDetailId = '';
    receiveClassScheduleDetail(classRegisters) {
        this.classDiaryData.frequency.class_schedule_detail = classRegisters.class_schedule_detail;
        const date = this.classDiaryData.frequency?.date;
        this.classScheduleDetailId = classRegisters?.class_schedule_detail?.id;
        const params = `date=${date}&class_schedule_detail_id=${this.classScheduleDetailId}`;

        this.getClassDiary(this.disciplineId, params);
    }

    receiveTextInputs(classRegisters) {
        Object.keys(classRegisters).forEach((key) => {
            this.classDiaryData.frequency[key] = classRegisters[key];
        });
    }

    receiveClassModalObject(modalObject) {
        if (Object.prototype.hasOwnProperty.call(modalObject, 'frequency_user')) {
            const frequencyUserIndex = this.classDiaryData.frequency_users.findIndex((frequency_user) => frequency_user.user.id === this.modalObject.frequency_user.user.id);
            if (frequencyUserIndex !== -1) {
                this.classDiaryData.frequency_users[frequencyUserIndex] = this.modalObject.frequency_user;
            }
        }
    }

    receiveAttendStatus(attend_status) {
        const { status } = attend_status;
        const { externalId } = attend_status;
        const index = this.classDiaryData.frequency_users.findIndex((frequency_user) => frequency_user.user.external_id === externalId);

        if (index !== -1) {
            this.classDiaryData.frequency_users[index].attend_status = status;
        }
    }

    calcPercentByStatus(status = 'present') {
        if (!this.classDiaryData) {
            return '0 (0%)';
        }
        if (this.classDiaryData.frequency_users.length === 0) return '0 (0%)';

        const total = this.classDiaryData.frequency_users.length;
        const countByStatus = this.classDiaryData.frequency_users.filter((student) => student.attend_status === status || (status === 'present' && student.attend_status === null)).length;
        const percent = (countByStatus / total) * 100;

        return `${countByStatus} (${percent.toFixed(0)}%)`;
    }

    save() {
        const { frequency } = this.classDiaryData;
        let frequencyUsers = this.classDiaryData.frequency_users;

        if (frequencyUsers.length) {
            frequencyUsers = frequencyUsers.map((frequency_user) => ({
                user_id: frequency_user.user.id,
                attend_status: frequency_user.attend_status === null ? 'present' : frequency_user.attend_status,
                events: frequency_user.events
            }));
        }

        const params = {
            frequency: {
                date: frequency.date,
                class_schedule_detail_id: frequency.class_schedule_detail.id,
                intended_content: frequency.intended_content,
                covered_content: frequency.covered_content
            },
            frequency_users: frequencyUsers
        };

        this.classDiaryService.postClassDiary(this.disciplineId, params).subscribe({
            next: (res) => {
                const resFrequency = res.data;
                this.toggleClassDiary();
                this.toastrService.show(this.i18n.class_diary_successfully_saved, resFrequency.frequency.class_schedule_detail.name, { progressBar: true }, 'toast-success');
                this.getFrequencyRegisterCalendar(this.frequencyCalendar);
            }
        });
    }

    frequencyCalendar: any;
    getFrequencyRegisterCalendar(frequencyCalendar) {
        console.log('frequencyCalendar ', frequencyCalendar);
        const frequencyParams = frequencyCalendar;
        let params = '';
        if (Object.values(frequencyParams).length) {
            params = `month=${frequencyCalendar.month}&year=${frequencyCalendar.year}`;
        }

        if (params.includes('undefined')) {
            params = '';
        }
        console.log('params ', params);

        this.classDiaryService.getFrequencyCalendar(this.disciplineId, params).subscribe({
            next: (res) => {
                console.log('res ', res);
                this.frequencyCalendar = res;
            }
        });
    }

    cancel() {
        this.toggleClassDiary();
    }
}
