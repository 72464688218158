import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/pages/login/login.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-callback-google-login',
    templateUrl: './callback-google-login.component.html',
    styleUrls: ['./callback-google-login.component.scss']
})
export class CallbackGoogleLoginComponent implements OnInit {
    i18n: any = [];

    constructor(
        private route: ActivatedRoute,
        private loginService: LoginService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,

    ) {}

    ngOnInit(): void {
        this.getTranslations();
        this.checkCallbackLoginGoogle();
    }
    checkCallbackLoginGoogle() {
        const route = this.route.snapshot.queryParams;

        if (Object.prototype.hasOwnProperty.call(route, 'code')) {
            console.log('CALLBACK ', this.route);
            this.authGoogle(route.code);
        }
    }

    getTranslations(): void {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Login'),
            ...this.sharedService.getTranslationsOf('Errors'),
        };
    }

    authGoogle(code: any) {
        const params = {
            code,
        };

        this.loginService.loginWithGoogle(params).subscribe({
            next: (response) => {
                // Se usuário não aceitou os termos, abre modal e encaminha objeto de
                // resposta de
                this.sharedService.storeUserCredentials(response);
                if (response.terms !== null) {
                    this.loginService.authObject$.next(response);
                    this.loginService.openModalPolicyAndPrivacy$.next({
                        openModal: true,
                        readOnly: false,
                    });
                } else {
                    this.loginService.setGoogleCredencials(response);
                    this.sharedService.loggedIn.next(true);
                    this.loginService.isRedirectRoute();
                }
            },
            error: (err) => {
                if (err.status === 422) {
                    this.platModalService.toggle(
                        'message',
                        this.i18n?.[err?.error?.key_error],
                        'close'
                    );
                } else if (err.status === 404) {
                    this.platModalService.toggle(
                        'message',
                        this.i18n?.error_not_profile_login,
                        'close'
                    );
                } else {
                    this.platModalService.toggle(
                        'message',
                        this.i18n?.error_system_error,
                        'close'
                    );
                }

                this.sharedService.loggedIn.next(false);
            },
            complete: () => {

            },
        });
    }
}
