<div class="container__box">
  <button class="button-opt w-full" (click)="toggle()">
    <div class="flex justify-center items-center gap-2">
        <div class="font-bold text-16 text-customBlue-default">
          {{ i18n.grades_evaluative_resource }}
        </div>

        <button

            [@rotatedState]="collapseOption"
        >
            <span class="iconify w-8 h-8 text-customBlue-default" data-icon="iconamoon:arrow-up-2-fill"></span>
        </button>
    </div>
  </button>
  <div [@collapse]="collapseOption" class="list">
    <form class="my-3 w-full" [formGroup]="gradesConfigForm">
      <div class="text-customGray-dark pt-2">
        <div>
          <label>{{ i18n.grades_period }}</label>
          <select
            formControlName="period_id"
            #periodId
            class="select truncate pr-7"
            (change)="
              getPeriodSelected(periodId.value);
              checkCalculationType(periodId.value, categoryValue.value);
              changePeriod()
            "
          >
            <option [value]="undefined" disabled selected>
              {{ i18n.grades_select }}
            </option>
            <option
              *ngFor="let period of periods; let i = index"
              [value]="period.id"
            >
              {{ period?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="text-customGray-dark pt-2">
        <div>
          <label>{{ i18n.grades_category }}</label>
          <select
            formControlName="category_id"
            #categoryValue
            class="select truncate pr-7"
            (change)="
              emitValue();
              checkCalculationType(periodId.value, categoryValue.value)
            "
          >
            <option [value]="undefined" disabled selected>
              {{ selectCategoriesPlaceholder }}
            </option>
            <option *ngFor="let category of categories" [value]="category.id">
              {{ category?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="text-customGray-dark pt-2" *ngIf="available_grade !== null">
        <label>{{ labelWeightName }}</label>
        <input
          type="text"
          formControlName="weight"
          #weightValue
          [ngClass]="{
            'disabled:opacity-20 cursor-not-allowed':
              available_grade === null || available_grade === 0
          }"
          (input)="validateWeight($event)"
          class="border border-customGray-default p-2 mb-2 w-full"
        />
        <input
          #availableGradeValue
          type="number"
          hidden
          [value]="available_grade"
        />
        <div class="text-customBlue-default italic font-bold pt-2">
          <div>
            <span>{{ getTraslatePoints(available_grade) }}</span>
          </div>
        </div>
      </div>
      <div class="text-customGray-dark pt-2" *ngIf="assignment === 'Forum'">
        <div>
          <label>{{ i18n.grades_forum_calculation_type }}</label>
          <select
            formControlName="composition_type"
            #compositionTypeValue
            class="select truncate pr-7"
            (change)="emitValue()"
          >
            <option [value]="undefined" disabled selected>
              {{i18n.grades_forum_select_calculation_type}}
            </option>
            <option *ngFor="let composition of compositionTypes" [value]="composition.id">
              {{ composition?.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="text-customGray-dark pt-2"
        *ngIf="available_grade !== null && gradesSettings.rubric_enable"
      >
        <span
          class="text-center block text-14 text-customOrange-default mb-2"
          *ngIf="validationPointsRubrica"
          >{{ i18n.disciplines_content_rubric_validation_points }}</span
        >
        <div class="relative">
          <app-display-panel
            #displayPanel
            [style]="{ position: 'absolute' }"
            [custom_radius]="'10px'"
            [hide_arrow]="true"
            [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
            [newStyle]="{ top: '-13.125rem', right: '-0.1875rem' }"
          >
            <small>{{
              i18n.disciplines_content_help_rubric_create_tooltip
            }}</small>
          </app-display-panel>
        </div>
        <button
          type="button"
          class="flex items-center justify-center gap-2 border border-customBlue-default p-2 rounded w-full text-customBlue-default font-medium"
          [disabled]="isButtonCreateRubricDisabled || gradesConfigForm.invalid"
          [ngClass]="{'disabled:opacity-20 cursor-not-allowed': isButtonCreateRubricDisabled || gradesConfigForm.invalid}"
          (click)="goCreateRubrica()"
        >
          {{ i18n.disciplines_content_rubric_create }}
          <div (mouseenter)="displayPanel.toggle()">
            <span class="iconify-inline text-18" data-icon="carbon:help"></span>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
