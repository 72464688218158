<section
  class="px-8 my-8 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default">
  <h1 class="text-customBlue-default font-bold text-xl">
    {{ i18n.disciplines_settings_visibility_class }}
  </h1>
  <ng-container *ngIf="!isLoading">
    <form class="text-customGray-dark" [formGroup]="settingsVisibilityForm">
      <div class="sm:w-1/2 mt-5">
          <label class="mb-1">{{ i18n.disciplines_settings_visibility_class }}</label>
          <select 
            class="text-customGray-dark w-full border border-customGray-grayTwo bg-customWhite-default rounded p-2" formControlName="visibility" required>
            <ng-container *ngFor="let tipo of settingsVisibility | keyvalue">
              <option [value]="'public'">
                {{ i18n.disciplines_settings_public_visibility }}
              </option>
              <option [value]="'private'">
                {{ i18n.disciplines_settings_visibility_private }}
              </option>
            </ng-container>
          </select>
      </div>
      <div class="flex flex-row-reverse justify-end gap-3 my-4">
        <label>{{
          i18n.disciplines_settings_visibility_requires_manual_approval
        }}</label>
        <input
          formControlName="manual_accept"
          id="toogle"
          class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
          type="checkbox"
        />
      </div>
      <div class="flex row justify-end">
        <div>
          <button
            class="border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray text-customWhite-default bg-customBlue-default"
            (click)="postVisibilitySettings()"
            [disabled]="!buttonActive"
            [ngClass]="{
              'disabled:opacity-20 cursor-not-allowed': !buttonActive
            }"
          >
            {{ i18n.disciplines_settings_visibility_save }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="flex justify-center items-center h-72">
      <app-bullets-loader></app-bullets-loader>
    </div>
  </ng-container>
</section>
