import {
    Component, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-display-panel',
    templateUrl: './display-panel.component.html',
    styleUrls: ['./display-panel.component.scss']
})
export class DisplayPanelComponent implements OnInit, OnDestroy {
    @Input() custom_padding = '';
    @Input() custom_radius = '10px';
    @Input() style: any = { x: 0, y: 0 };
    @Input() newStyle: any;
    @Input() z_index: any;
    @Input() arrow_style: any;
    @Input() arrow_deslocation: any;
    @Input() visible = false;
    @Input() hasStyle = false;
    @Input() breakpoint_X: string;
    @Input() panel_style: any = {};
    @Input() hide_arrow = false;
    @Input() lockWidth = false;
    @Input() uniqueId!: string;

    wrapper: any;
    wrapperWidth: any;
    wrapperBorderRadius: any;
    display: any;
    diamond: any;
    widthCompressionRatio: number;
    diamondLeftPosition: string;
    diamondWidth: any;
    wrapperHeight: any;
    breakpointObserverKey: symbol;

    constructor(public element: ElementRef, private sharedService: SharedService) { }

    ngOnInit(): void {
        setTimeout(this.initializeComponent.bind(this), 1000);
        this.getMediaQuerySubscription();
    }

    initializeVariables() {
        this.wrapper = this.element.nativeElement.querySelector('.display-panel-wrapper');
        this.display = this.element.nativeElement.querySelector('.display-panel');
        this.wrapperWidth = this.wrapper.scrollWidth;
        this.wrapperHeight = this.wrapper.scrollHeight;
        this.diamond = this.element.nativeElement.querySelector('.diamond-wrapper');
        this.diamondWidth = this.diamond.scrollWidth;
    }

    initializeComponent() {
        this.initializeVariables();

        this.setWrapperStyle();
        this.setCustomPadding();
        this.setCustomRadius();
        this.getPercentageWidthRatio();
        this.setArrowDeslocation();
        this.setTransform();
        if (this.lockWidth) this.setContentWidth();
    }

    toggle($event?:any) {
        if ($event) $event.stopPropagation();
        this.visible = !this.visible;
        this.sharedService.displayPanelStatus$.next(this.visible);
    }

    setWrapperStyle() {
        Object.keys(this.style).forEach((key) => {
            // (E.g.: to transform z-index into zIndex) ->
            let treatedKey = key;

            if (key.includes('-')) {
                key.split('-').forEach((word, index) => {
                    if (index > 0) word = word.charAt(0).toUpperCase() + word.slice(1);

                    treatedKey = key.replace(`-${word}`, word);
                });
            }

            this.wrapper.style[treatedKey] = `${this.style[key]}`;
        });
    }

    setArrowDeslocation() {
        let arrowDeslocation: any = String(this.arrow_deslocation);

        if (arrowDeslocation.includes('%')) arrowDeslocation = this.getArrowPercentage();

        arrowDeslocation.match(/px|\%/)
            ? this.diamondLeftPosition = arrowDeslocation
            : this.diamondLeftPosition = `${parseInt(arrowDeslocation)}%`;

        this.diamond.style.left = this.diamondLeftPosition;
    }

    getPercentageWidthRatio() {
        const widthWithoutBorderRadius = this.wrapperWidth - (this.wrapperBorderRadius);
        this.widthCompressionRatio = (Math.abs(((widthWithoutBorderRadius * 100) / this.wrapperWidth) - 100) / 100) * 2;
    }

    getArrowPercentage() {
        const input = parseInt(this.arrow_deslocation);
        const diference = input - 50;
        const finalResult = diference - (diference * this.widthCompressionRatio) + 50;

        return `${Math.floor(finalResult)}%`;
    }

    setCustomRadius() {
        const halfOfTheWrapperHeight = this.wrapperHeight / 2;
        const radius = Math.min(parseInt(this.custom_radius), halfOfTheWrapperHeight);

        this.display.style.borderRadius = `${radius}px`;
        this.wrapperBorderRadius = parseInt(this.display.style.borderRadius);
    }

    setCustomPadding() {
        if (this.custom_padding) this.display.style.padding = this.custom_padding;
    }

    setTransform() {
        this.diamond.style.transform = `translate3d(-${parseInt(this.diamondLeftPosition) * this.diamondWidth / 100}px, 0, 0)`;
    }

    getMediaQuerySubscription() {
        this.breakpointObserverKey = this.sharedService.callFunctionsOnBreakpoint(this.breakpoint_X, {
            after: () => {
                this.initializeComponent();
            },
            before: () => {
                this.initializeComponent();
            }
        });
    }

    setContentWidth() {
        this.display.style.width = `${this.wrapperWidth}px`;
    }

    ngOnDestroy() {
        this.sharedService.removeBreakpointObserver(this.breakpointObserverKey);
    }
}
