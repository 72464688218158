<div class="w-full" [formGroup]="Form" #calendarClick>
  <div class="input-container">
    <div class="flex">
        <button (click)="enableCalendar = !enableCalendar" class="inputDate">
        </button>
        <input
            [inputMask]="dateInputMask"
            type="text" 
            class="input-contents" 
            formControlName="{{ inputControlNameMask }}"
            autocomplete="off"
        >
    </div>

        <div
            [ngClass]="{ visbile: enableCalendar, hidden: !enableCalendar }"
            class="relative w-full calendar-container cursor-pointer"
        >
            <div class="w-full" [style.position]="position">
                <div class="relative">
                    <div
                        (click)="enableCalendar = false"
                        style="
                            position: absolute;
                            right: 5px;
                            z-index: 100;
                            height: 0;
                            top: 8px;
                        "
                    >
                        <img
                            src="../../../assets/svg/certificate/close.svg"
                            alt="close"
                        />
                    </div>
                    <p-calendar
                        #calendarDate
                        formControlName="{{ inputNameControlPicker }}"
                        [dateFormat]="
                            currentLanguage === 'en'
                                ? 'MM/dd/yyyy'
                                : 'dd/MM/yyyy'
                        "
                        [hourFormat]="
                            currentLanguage === 'en'
                                ? '12'
                                : '24'
                        "
                        [showTime]="true"
                        [inline]="true"
                        [minDate]="minDateValidation"
                        [readonlyInput]="false"
                        [styleClass]="'calendarStyle'"
                        [placeholder]="'01/01/2023 20:01'"
                        (onSelect)="
                            selectValueCalendar(
                                calendarDate.value,
                                inputNameControlPicker
                            )
                        "
                    >
                    </p-calendar>
                </div>
            </div>
        </div>
    </div>
</div>
