<div class="p-2">
    <div class="font-Montserrat font-bold text-customBlue-default text-12 mb-4">
        {{ i18n.class_diary_frequency }}
    </div>

    <ng-container *ngIf="classDiaryData">
        <div class="flex flex-col w-full">
            <!-- Header -->
            <div 
                class="grid-container w-full px-4 mb-4"
                *ngIf="classDiaryData.frequency_users.length"
            >
                <div
                    class="user-info font-Montserrat font-bold text-customBlue-default text-12"
                >
                    {{ i18n.class_diary_students }}
                </div>
                <div
                    class="presence-status font-Montserrat font-bold text-customBlue-default text-12 text-center"
                >
                    {{ i18n.class_diary_presence }}
                </div>
                <div
                    class="edit-button font-Montserrat font-bold text-customBlue-default text-12 text-center"
                >
                    Obs.
                </div>
            </div>

            <!-- Body -->
            <div class="container-scroll px-2">
                <ng-container
                    *ngFor="
                        let frequency_user of classDiaryData.frequency_users;
                        let i = index
                    "
                >
                    <div class="grid-container border border-customGray-lighterGray rounded-md p-2 mb-2">
                        <!-- User Info -->
                        <div class="user-info">
                            <app-user-frame
                                [style]="{
                                    width: '23px',
                                    height: '23px',
                                    font: '400 17px Roboto'
                                }"
                                [img_src]="frequency_user.user?.image"
                                [user_name]="frequency_user.user?.name"
                            >
                            </app-user-frame>
                            <div
                                pTooltip="{{ frequency_user.user?.name }}"
                                tooltipPosition="top"
                                class="text-customGray-swampGray text-16 font-Roboto"
                                style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                            >
                                {{ frequency_user.user?.name }}
                            </div>
                        </div>

                        <!-- Presence Status -->
                        <div 
                            class="presence-status"
                            style="height: 83%;"
                        >
                            <app-attend-status
                                (statusChange)="onStatusChange($event)"
                                [userExternalId]="
                                    frequency_user.user?.external_id || ''
                                "
                                [isDisabled]="
                                    classDiaryData.frequency
                                        .class_schedule_detail.id === null
                                "
                                [status]="
                                    frequency_user.attend_status || 'present'
                                "
                            ></app-attend-status>
                        </div>

                        <!-- Edit Button -->
                        <div class="edit-button">
                            <button
                                class="cursor-pointer"
                                (click)="
                                    openModalAbsenceNotes({
                                        frequency_user: frequency_user,
                                        type: 'absences_notes'
                                    })
                                "
                                [ngClass]="{
                                    'disabled:opacity-20 cursor-not-allowed':
                                        classDiaryData.frequency
                                            .class_schedule_detail.id === null
                                }"
                                [disabled]="
                                    classDiaryData.frequency
                                        .class_schedule_detail.id === null
                                "
                            >
                                <span
                                    class="iconify w-6 h-6 text-customBlue-default"
                                    data-icon="flowbite:edit-outline"
                                ></span>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="!classDiaryData.frequency_users.length"
                >
                    <div 
                        class="flex justify-center items-center text-18 text-customGray-default text-center font-medium"
                        style="height: calc(100vh - 400px);"
                    >
                        {{ i18n.class_diary_no_users_message }}
                    </div>
                </ng-container>
            </div>

        </div>
    </ng-container>
</div>
