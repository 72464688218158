import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CountdownComponentModule } from 'src/app/components/countdown/countdown.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { ThirdPartyCookieModule } from 'src/app/components/third-party-cookie/third-party-cookie.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { AccessibilityLoginComponent } from './components/accessibility-login/accessibility-login.component';
import { CallbackGoogleLoginComponent } from './components/google-login/components/callback-google-login/callback-google-login.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { HelpLinksComponent } from './components/help-links/help-links.component';
import { MicrosoftLoginComponent } from './components/microsoft-login/microsoft-login.component';
import { SSOLoginComponent } from './components/sso-login/sso-login.component';
import { ModalResetPasswordComponent } from './components/standalone-login/components/modal-reset-password/modal-reset-password.component';
import { ModalResetValidationCodeComponent } from './components/standalone-login/components/modal-reset-validation-code/modal-reset-validation-code.component';
import { StandaloneLoginComponent } from './components/standalone-login/standalone-login.component';
import { ModalPolicyAndPrivacyComponent } from './components/use-terms/components/modal-policy-and-privacy/modal-policy-and-privacy.component';
import { ModalUseTermsComponent } from './components/use-terms/components/modal-use-terms/modal-use-terms.component';
import { UseTermsComponent } from './components/use-terms/use-terms.component';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
    declarations: [
        LoginComponent,
        GoogleLoginComponent,
        MicrosoftLoginComponent,
        SSOLoginComponent,
        StandaloneLoginComponent,
        ModalResetPasswordComponent,
        ModalResetValidationCodeComponent,
        HelpLinksComponent,
        UseTermsComponent,
        ModalUseTermsComponent,
        ModalPolicyAndPrivacyComponent,
        AccessibilityLoginComponent,
        CallbackGoogleLoginComponent,
    ],
    exports: [
        ModalUseTermsComponent,
        ModalPolicyAndPrivacyComponent,
    ],
    imports: [
        SharedModule,
        LoginRoutingModule,
        CountdownComponentModule,
        GenericModalModule,
        MessageModalModule,
        EditorModule,
        ThirdPartyCookieModule,
    ]
})
export class LoginModule { }
